const reducedMotionOn = false;
let simplex;

if (!window.isServer) {
        const SimplexNoise = window.SimplexNoise;
        simplex = new SimplexNoise();
}



function cameraShake(time, intensity, speed) {
        if (reducedMotionOn || !simplex) {
                return { x: 0, y: 0, rotate: 0, scale: 1 };
        }

        const velocity = 0.02 * speed;
        const x = simplex.noise2D(time * velocity, 2) * intensity;
        const y = simplex.noise2D(time * velocity, 1) * intensity;
        const rotate = simplex.noise2D(time * velocity, 3) * intensity * 0.01;
        const scale = 1 + simplex.noise2D(time * velocity, 4) * intensity * 0.01;
        return { x, y, rotate, scale };
}

// Example
// const time = Date.now() * 0.001;
// const { x, y, rotate, scale } = cameraShake(time, 1, 1);
// ctx.save();

export default cameraShake;