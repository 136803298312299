class CreateSyncedAudio {
    constructor(audioUrl) {
        this.audioUrl = audioUrl;
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.buffer = null;
        this.isPlaying = false;
        this.bassLevelCache = {};
        this.volume = 1;
        this.paused = false;
        this.fetchAudio();
    }

    async setVolume(volume) {
        this.volume = volume;
        if (this.gainNode) {
            this.gainNode.gain.setValueAtTime(this.volume, this.audioContext.currentTime);
        }
    }

    async fetchAudio(audioUrl, cb) {
        try {
            if (audioUrl) {
                this.audioUrl = audioUrl;
            }
            if (!this.audioUrl) {
                console.log('No audio URL provided');
                return;
            }
            this.isFetching = new Promise(async resolve => {
                this.resolveFetch = resolve;
            });
            const response = await fetch(this.audioUrl);
            const arrayBuffer = await response.arrayBuffer();
            this.buffer = await this.audioContext.decodeAudioData(arrayBuffer);
            const duration = this.buffer.duration;
            cb && cb(duration);
            this.resolveFetch();
        } catch (error) {
            console.error('Error fetching or decoding audio:', error);
        }
    }

    async play(currentTime) {
        try {
            if (this.buffer) {
                currentTime = currentTime % this.buffer.duration;
            }

            if (!this.audioContext) {
                console.log('Audio context not ready');
                return;
            }
            if (this.isFetching) {
                await this.isFetching;
            }
            if (this.paused) {
                this.paused = false;
                return;
            }
            if (!this.isPlaying) {
                this.startSource(currentTime);
            } else {
                if (Math.abs(currentTime - this.currentTime) > 0.5) {
                    console.log('Resynced');
                    this.startSource(currentTime);
                }
            }
            this.currentTime = currentTime;
        } catch (error) {
            console.error('Error during play:', error);
        }
    }

    startSource(currentTime) {
        if (this.source) {
            this.source.stop();
        }
        this.source = this.audioContext.createBufferSource();
        this.source.buffer = this.buffer;

        this.gainNode = this.audioContext.createGain();
        this.gainNode.gain.setValueAtTime(this.volume, this.audioContext.currentTime);

        this.source.connect(this.gainNode);
        this.gainNode.connect(this.audioContext.destination);

        this.source.start(0, currentTime);
        this.isPlaying = true;
    }

    pause() {
        try {
            if (this.source) {
                this.source.stop();
                this.paused = true;
                this.isPlaying = false;
            }
        } catch (error) {
            console.error('Error during pause:', error);
        }
    }

    async prefetchBassLevel(setStatus) {
        try {
            if (Object.keys(this.bassLevelCache).length) {
                return;
            }

            if (this.isPrefetching) {
                return;
            }
            this.isPrefetching = true;

            if (!this.buffer) {
                await this.fetchAudio();
            }
            const offlineContext = new OfflineAudioContext(1, this.buffer.length, this.buffer.sampleRate);
            const source = offlineContext.createBufferSource();
            source.buffer = this.buffer;
            const filter = offlineContext.createBiquadFilter();
            filter.type = 'lowpass';
            source.connect(filter);
            filter.connect(offlineContext.destination);
            source.start();
            setStatus('Processing audio for bass levels...');
            const renderedBuffer = await offlineContext.startRendering();
            this.cacheBassLevels(renderedBuffer);
            setStatus('Bass level caching complete.');
        } catch (error) {
            console.error('Error during bass level prefetching:', error);
        }
    }

    cacheBassLevels(renderedBuffer) {
        let i = 0;
        const interval = 0.05;
        let prevBassLevel = 0;
        while (i < renderedBuffer.duration) {
            const currentBassLevel =
                Math.abs(renderedBuffer.getChannelData(0)[Math.floor(i * renderedBuffer.sampleRate)]) - prevBassLevel;
            this.bassLevelCache[i.toFixed(2)] = currentBassLevel;
            prevBassLevel = Math.abs(renderedBuffer.getChannelData(0)[Math.floor(i * renderedBuffer.sampleRate)]);
            i += interval;
        }
        console.log('Bass level cache:', this.bassLevelCache);
    }

    getBassLevel(currentTime) {
        const time = currentTime.toFixed(2);
        return this.bassLevelCache[time] || 0;
    }

    getDuration() {
        return this.buffer.duration;
    }

    async getCurrentTime() {
        try {
            return this.audioContext.currentTime;
        } catch (error) {
            console.error('Error getting current time:', error);
        }
    }
}

const audioRender = new CreateSyncedAudio();

export {audioRender};
export default CreateSyncedAudio;
