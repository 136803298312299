import {FFmpeg} from '@ffmpeg/ffmpeg'; // Updated imports
import {fetchFile, toBlobURL} from '@ffmpeg/util';
import moment from 'moment';

const ffmpegOutputToProgress = (prefix, message, duration, setStatus) => {
    // Find lines like frame=  325 fps=0.0 q=-1.0 Lsize=    3974kB time=00:00:13.04 bitrate=2494.7kbits/s speed=18.2x
    const progress = message.match(/time=([^ ]+) bitrate=/);
    if (progress) {
        const time = moment.duration(progress[1]).asSeconds();
        let percentage = (time / duration) * 100;
        if (percentage < 0) {
            percentage = 0;
        }
        setStatus(`${prefix}Progress: ${percentage.toFixed(2)}%`);
    } else {
        return;
    }
};

const getFFMPEG = async setStatus => {
    let ffmpeg = window.ffmpeg ? await window.ffmpeg : null;
    let log = window.ffmpeg_logger;

    window.currentLogFunc = setStatus
        ? setStatus
        : () => {
              // Skip for now
          };

    if (!ffmpeg) {
        window.ffmpeg = new Promise(async resolve => {
            window.ffmpeg_logger = message => {
                window.currentLogFunc && window.currentLogFunc(message);
            };
            log = window.ffmpeg_logger;

            ffmpeg = new FFmpeg();
            log('Loading ffmpeg...');

            ffmpeg.on('log', ({message}) => {
                // messageRef.current.innerHTML += message;
                log(message);
            });

            const baseURL = '';

            await ffmpeg.load({
                coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
                wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm')
            });

            resolve(ffmpeg);
        });
    }

    return ffmpeg;
};

export {getFFMPEG, fetchFile, ffmpegOutputToProgress};
