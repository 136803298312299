// classRegistry.js

const classRegistry = {};

/**
 * Registers a class constructor with a given name.
 * @param {string} className - The name of the class.
 * @param {Function} classConstructor - The class constructor.
 */
export const registerClass = (className, classConstructor) => {
    classRegistry[className] = classConstructor;
};

/**
 * Retrieves the class constructor for a given class name.
 * @param {string} className - The name of the class.
 * @returns {Function|null} The class constructor or null if not found.
 */
export const getClass = className => {
    return classRegistry[className] || null;
};
