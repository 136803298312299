import React, {useState, useEffect} from 'react';
import srtParser2 from 'srt-parser-2';
import {Box, Table, Thead, Tbody, Tr, Th, Td, Input, IconButton, VStack, HStack, Button} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import {FiChevronUp, FiChevronDown, FiPlus, FiMinus, FiEdit2} from 'react-icons/fi';

const MotionTr = motion(Tr);

const SrtEditor = ({value, onChange}) => {
    const [subtitles, setSubtitles] = useState([]);
    const parser = new srtParser2();

    useEffect(() => {
        const parsed = parser.fromSrt(value);
        setSubtitles(parsed);
    }, [value]);

    const updateSubtitles = newSubtitles => {
        setSubtitles(newSubtitles);
        // const newSrtText = parser.toSrt(newSubtitles);
        // onChange(newSrtText);
    };

    const adjustTime = (index, field, adjustment) => {
        const newSubtitles = [...subtitles];
        const time = newSubtitles[index][field];
        const [hours, minutes, seconds, milliseconds] = time.split(/[,:]/);
        let totalMs =
            parseInt(hours) * 3600000 + parseInt(minutes) * 60000 + parseInt(seconds) * 1000 + parseInt(milliseconds);
        totalMs += adjustment;
        const newTime = new Date(totalMs).toISOString().substr(11, 12).replace('.', ',');
        newSubtitles[index][field] = newTime;
        updateSubtitles(newSubtitles);
    };

    const updateText = (index, newText) => {
        const newSubtitles = [...subtitles];
        newSubtitles[index].text = newText;
        updateSubtitles(newSubtitles);
    };

    const addLine = (index, position) => {
        const newSubtitles = [...subtitles];
        const newLine = {
            id: (parseInt(newSubtitles[newSubtitles.length - 1].id) + 1).toString(),
            // Start previous subtitle's end time + 1
            startTime: newSubtitles[index].endTime,
            // End previous subtitle's end time + 4
            endTime: newSubtitles[index].endTime
                .replace(/\d\d\,/g, match => (parseInt(match) + 4).toString() + ',')
                .replace(/(\d\d\,)\d\d\d/, (match, p1) => p1 + '000'),
            text: 'New subtitle'
        };
        newSubtitles.splice(index + (position === 'after' ? 1 : 0), 0, newLine);
        updateSubtitles(newSubtitles);
    };

    return (
        <>
            <Box overflow="auto" w="100%" height="400">
                <Table size="sm" variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Start</Th>
                            <Th>End</Th>
                            <Th>Text</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subtitles.map((subtitle, index) => (
                            <MotionTr
                                key={subtitle.id}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                transition={{duration: 0.2}}
                            >
                                <Td>{subtitle.id}</Td>
                                <Td>
                                    <VStack align="start" spacing={1}>
                                        <Box>{subtitle.startTime}</Box>
                                        <HStack spacing={1}>
                                            <IconButton
                                                icon={<FiMinus />}
                                                size="xs"
                                                aria-label="Decrease start time"
                                                onClick={() => adjustTime(index, 'startTime', -250)}
                                            />
                                            <IconButton
                                                icon={<FiPlus />}
                                                size="xs"
                                                aria-label="Increase start time"
                                                onClick={() => adjustTime(index, 'startTime', 250)}
                                            />
                                        </HStack>
                                    </VStack>
                                </Td>
                                <Td>
                                    <VStack align="start" spacing={1}>
                                        <Box>{subtitle.endTime}</Box>
                                        <HStack spacing={1}>
                                            <IconButton
                                                icon={<FiMinus />}
                                                size="xs"
                                                aria-label="Decrease end time"
                                                onClick={() => adjustTime(index, 'endTime', -250)}
                                            />
                                            <IconButton
                                                icon={<FiPlus />}
                                                size="xs"
                                                aria-label="Increase end time"
                                                onClick={() => adjustTime(index, 'endTime', 250)}
                                            />
                                        </HStack>
                                    </VStack>
                                </Td>
                                <Td>
                                    <Input
                                        size="sm"
                                        value={subtitle.text}
                                        onChange={e => updateText(index, e.target.value)}
                                    />
                                </Td>
                                <Td>
                                    <HStack spacing={1}>
                                        <IconButton
                                            icon={<FiChevronUp />}
                                            size="xs"
                                            aria-label="Add line before"
                                            onClick={() => addLine(index, 'before')}
                                        />
                                        <IconButton
                                            icon={<FiChevronDown />}
                                            size="xs"
                                            aria-label="Add line after"
                                            onClick={() => addLine(index, 'after')}
                                        />
                                        <IconButton
                                            icon={<FiEdit2 />}
                                            size="xs"
                                            aria-label="Edit line"
                                            onClick={() => {
                                                /* Implement edit functionality */
                                            }}
                                        />
                                    </HStack>
                                </Td>
                            </MotionTr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <Button
                onClick={() => {
                    const newSrtText = parser.toSrt(subtitles);
                    onChange(newSrtText);
                }}
            >
                Save
            </Button>
        </>
    );
};

export default SrtEditor;
