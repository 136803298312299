
const canvasImage = ({
    x, y, rotate, scale, opacity,
    img, ctx,
    width, height,
    canvasWidth, canvasHeight
}) => {
    // Scale is 0.1 to 2, but we should offset it so its -1 to 1
    scale = (scale - 1) * 0.9;

    ctx.save();

    ctx.globalAlpha = opacity;

    // Move to the center of the canvas
    ctx.translate(canvasWidth / 2, canvasHeight / 2);

    // Translate to the center of the image and rotate
    ctx.translate(x, y);
    ctx.rotate(rotate * Math.PI / 180);

    // Scale image
    ctx.scale(scale, scale);

    // Draw image
    ctx.drawImage(img, -width / 2, -height / 2, width, height);

    ctx.restore();

};

export default canvasImage;