import {getFFMPEG, fetchFile} from '../getFFMPEG.js';
import React, {useState, useMemo, useEffect, useRef, Suspense} from 'react';

import axios from 'axios';
import styled, {css} from 'styled-components';
import {motion, AnimatePresence} from 'framer-motion';
import OpenAI from 'openai';
import moment from 'moment';

// Chakra
import {Alert} from '@chakra-ui/react';
import MultiTypeInput from '../MultiTypeInput.js';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react';
import {Button, ButtonGroup, Input, Textarea} from '@chakra-ui/react';
import {Progress} from '@chakra-ui/react';
import {Box as ChakraBox} from '@chakra-ui/react';
import {Select} from '@chakra-ui/react';

// Download icon from react-icons
import {MdFileDownload} from 'react-icons/md';

// Check icon
import {FaCheck} from 'react-icons/fa';

import srtParser2 from 'srt-parser-2';

import WordSplitter from './WordSplitter.js';

// Smooth scroll, and prevent scroll jumping
const smoothScrollToBottom = element => {
    requestAnimationFrame(() => {
        element.scrollTop = element.scrollHeight;
    });
};

const TextareaDebounced = React.memo(({value, onChange, ...props}) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        if (value === localValue) {
            return;
        }

        const timeout = setTimeout(() => {
            onChange({target: {value: localValue}});
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [localValue, onChange]);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <motion.div
            // Animate height auto when showing and hiding
            initial={{height: 0}}
            animate={{height: 'auto'}}
            exit={{height: 0}}
            transition={{duration: 0.3}}
            style={{
                display: 'flex',
                overflow: 'hidden'
            }}
        >
            <Textarea value={localValue} onChange={e => setLocalValue(e.target.value)} {...props} />
        </motion.div>
    );
});

const ProgressLabel = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;

    ${props =>
        props.loading &&
        css`
            animation: blinker 1s linear infinite;
        `}

    @keyframes blinker {
        50% {
            opacity: 0.6;
        }
    }
`;

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        if (key.endsWith('_')) {
            return initialValue;
        }
        const storedValue = window.localStorage.getItem(key);
        if (storedValue) {
            try {
                return JSON.parse(storedValue);
            } catch (error) {
                return storedValue === 'undefined' ? initialValue : storedValue;
            }
        }
        return initialValue;
    });

    useEffect(() => {
        if (key.endsWith('_')) {
            return;
        }
        const storedValue = window.localStorage.getItem(key);
        if (storedValue) {
            try {
                setValue(JSON.parse(storedValue));
            } catch (error) {
                setValue(storedValue === 'undefined' ? initialValue : storedValue);
            }
        }
    }, [key]);

    useEffect(() => {
        if (key.endsWith('_')) {
            return;
        }
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

var parser = new srtParser2();

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')},${ms.toString().padStart(3, '0')}`;
}

const CustomBox = styled(ChakraBox)`
    color: black;
    /* Make scrollbars light */
    ::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    // All placeholders
    ::-webkit-input-placeholder {
        color: #888;
    }
`;

const processWordLevelData = (data, longPauseMultiplier) => {
    let srtContent = '';
    let srtIndex = 1;
    let currentSentence = [];
    let startTime = null;

    const formatTime = seconds => {
        return moment.utc(seconds * 1000).format('HH:mm:ss,SSS');
    };

    const gaps = data.words.slice(1).map((word, i) => word.start - data.words[i].end);
    const averageGap = gaps.reduce((sum, gap) => sum + gap, 0) / gaps.length;

    data.words.forEach((word, index) => {
        if (!startTime) startTime = word.start;
        currentSentence.push(word.word);

        const isLastWord = index === data.words.length - 1;
        const nextWord = data.words[index + 1];
        const timeTillNextWord = nextWord ? nextWord.start - word.end : 0;

        if (word.word.match(/[.!?]$/) || timeTillNextWord > averageGap * longPauseMultiplier || isLastWord) {
            const endTime = nextWord ? nextWord.start : word.end;
            const sentenceText = currentSentence.join(' ').trim();

            srtContent += `${srtIndex}\n`;
            srtContent += `${formatTime(startTime)} --> ${formatTime(endTime)}\n`;
            srtContent += `${sentenceText}\n\n`;

            srtIndex++;
            currentSentence = [];
            startTime = endTime;
        }
    });

    return srtContent.trim();
};

const Box = styled(ChakraBox)`
    color: black;
    /* Make scrollbars light */
    ::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const AccessTokenInput = ({value, onChange}) => {
    const [showKey, setShowKey] = useState(value.length > 0 ? false : true);

    const hiddenValue = useMemo(() => {
        return showKey ? value : `OpenAI API Access Token: ${value.slice(0, 5)} ${'*'.repeat(4)} ${value.slice(-5)}`;
    }, [showKey, value]);

    return (
        <div
            onClick={() => setShowKey(true)}
            style={{
                cursor: 'pointer',
                userSelect: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <Input
                variant={showKey ? 'outline' : 'filled'}
                style={!showKey ? {pointerEvents: 'none'} : {}}
                value={hiddenValue}
                onChange={onChange}
                placeholder="OpenAI API Key"
                onBlur={() => setShowKey(false)}
            />
        </div>
    );
};

const closest = (el, selector) => {
    while (el && el.nodeType === 1) {
        if (el.matches(selector)) {
            return el;
        }
        el = el.parentNode;
    }
    return null;
};

// Get ffmpeg instance preloaded
getFFMPEG();

let mp3Cache = window.mp3Cache || {};
window.mp3Cache = mp3Cache;

const convertToMp3 = async (audioFile, audioLength, folderHandle, name, logger) => {
    if (mp3Cache[name]) {
        return await mp3Cache[name];
    }

    mp3Cache[name] = new Promise(async (resolve, reject) => {
        if (folderHandle) {
            folderHandle = await folderHandle.getDirectoryHandle('mp3', {create: true});

            const files = await folderHandle.values();
            for await (const file of files) {
                if (file.name === name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3') {
                    const data = await file.getFile();
                    const audioFileMp3 = new File([data], file.name, {
                        type: 'audio/mp3'
                    });
                }
            }

            for await (const file of files) {
                await file.removeEntry();
            }

            mp3Cache = {};
        }

        mp3Cache[name] = new Promise(async (resolve, reject) => {
            const ffmpeg = await getFFMPEG(logger);

            await ffmpeg.writeFile('ogfile', await fetchFile(audioFile));

            console.log('Converting to mp3... ' + audioLength + 's');

            await ffmpeg.exec(['-i', 'ogfile', '-preset', 'ultrafast', '-codec:a', 'libmp3lame', '-b:a', '160k', name]);

            const data = await ffmpeg.readFile(name);

            const audioFileMp3 = new File([data], name.replace(/\.(mp3|wav|ogg)$/, '.mp3'), {
                type: 'audio/mp3'
            });

            if (folderHandle) {
                await folderHandle.getFileHandle(name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3', {create: true});
                const writable = await folderHandle.getFileHandle(name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3', {
                    create: true
                });
                const writableStream = await writable.createWritable();
                await writableStream.write(data);
                await writableStream.close();
            }

            resolve(audioFileMp3);
        });

        resolve(await mp3Cache[name]);
    });

    return await mp3Cache[name];
};

const triggerDownload = (content, type, filename) => {
    const blob = new Blob([content], {type: `text/${type}`});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.${type}`;
    a.click();
    URL.revokeObjectURL(url);
};

const useHash = (value, valueProp) => {
    // Value could be file or string
    const [hash, setHash] = useState('');

    useEffect(() => {
        if (valueProp) {
            setHash(valueProp);
            return;
        }

        if (value instanceof File) {
            const reader = new FileReader();
            reader.onload = function () {
                const arrayBuffer = this.result;
                crypto.subtle.digest('SHA-256', arrayBuffer).then(hash => {
                    const hashArray = Array.from(new Uint8Array(hash));
                    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                    setHash(hashHex);
                });
            };
            reader.readAsArrayBuffer(value);
        } else if (typeof value === 'string') {
            crypto.subtle.digest('SHA-256', new TextEncoder().encode(value)).then(hash => {
                const hashArray = Array.from(new Uint8Array(hash));
                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                setHash(hashHex);
            });
        }
    }, [value]);

    return hash;
};

const roundStartAndEndTimes = wordsText => {
    // Rounds to 2 decimal places
    return wordsText.replace(/(\d+\.\d{2})\d+/g, '$1');
};

const prepareLyrics = lyrics => {
    return (
        lyrics
            // Remove Chorus written in case insensitive along with the entire line
            .replace(/^.*chorus.*$/gim, '')
            // Remove lines with specific patterns (vers.*, chorus.*, intro.*, outro.*, etc.)
            .replace(
                /^.*(vers.*?|chorus.*?|intro.*?|outro.*?|bridge.*?|hook.*?|pre-chorus.*?|pre-verse.*?|post-chorus.*?|post-verse.*?|interlude.*?).*$/gim,
                ''
            )
            // Remove like "Refräng" or "Vers 1" and its whole line, the sweddish version of chorus and verse
            .replace(
                /^.*(refräng|vers|intro|outro|utro|brygga|hook|pre-chorus|pre-verse|post-chorus|post-verse|interlude|mellanspel|spoken|bridge|chorus|verse).*$/gim,
                ''
            )
            // Insert line breaks after , and . and ? and ! and :
            .replace(/[,\.!?] ?/g, '\n')
            // Replace , and . with line breaks
            // .replace(/[,\.] ?/g, '\n')
            // Remove empty lines
            .replace(/^\s*[\r\n]/gm, '')
    );
};

// Show a toggleable text editor
const colorize = (text, color) => {
    return `<span style="color: ${color}">${text}</span>`;
};
const PromptEditor = ({promptTemplate, setPromptTemplate, wordsData, lyrics}) => {
    const [showPrompt, setShowPrompt] = useState(false);
    const [preview, setPreview] = useState(true);

    const withLyrics = useMemo(() => {
        const wordsText = (wordsData.words || []).map(word => `${word.word}|${word.start}|${word.end}`).join(' ');
        return (
            showPrompt &&
            promptTemplate
                .replace(/\[words\]/g, colorize(roundStartAndEndTimes(wordsText).trim(), 'blue'))
                .replace(/\[lyrics\]/g, colorize(prepareLyrics(lyrics || '').trim(), 'green'))
        );
    }, [showPrompt, promptTemplate, wordsData, lyrics]);

    return (
        <CustomBox my={4}>
            <AnimatePresence>
                {showPrompt &&
                    (preview ? (
                        <TextareaDebounced
                            as={motion.div}
                            key="prompt"
                            style={{
                                background: 'white',
                                cursor: 'pointer',
                                height: '200px',
                                overflow: 'auto',
                                // Wrap text
                                whiteSpace: 'pre-wrap'
                            }}
                            onClick={e => {
                                setPreview(false);
                            }}
                            // Dangerously set inner HTML
                            dangerouslySetInnerHTML={{__html: withLyrics}}
                        />
                    ) : (
                        <TextareaDebounced
                            key="prompt"
                            value={promptTemplate}
                            onChange={e => {
                                setPromptTemplate(e.target.value);
                            }}
                            style={{
                                background: 'white',
                                height: '200px'
                            }}
                            onBlur={e => {
                                setPreview(true);
                            }}
                        />
                    ))}

                <Button
                    key="togglePrompt"
                    as={motion.button}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    onClick={() => {
                        setShowPrompt(!showPrompt);
                    }}
                    mt={2}
                    colorScheme={showPrompt ? 'purple' : 'gray'}
                >
                    {showPrompt ? 'Hide Prompt' : 'Edit Prompt (advanced)'}
                </Button>

                {/* Reset prompt */}
                {showPrompt && (
                    <Button
                        key="resetPrompt"
                        as={motion.button}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        onClick={() => {
                            if (window.confirm('Are you sure you want to reset the prompt?')) {
                                setPromptTemplate(defaultPromptTemplate);
                            }
                        }}
                        mt={2}
                        colorScheme="gray"
                    >
                        Reset Prompt
                    </Button>
                )}
            </AnimatePresence>
        </CustomBox>
    );
};

const defaultPromptTemplate = `### Goal
Correct the timecoded text by matching it to the provided lyrics, making necessary word adjustments (merge, split, replace).

### Timecoded Text Format
- **Format:**  
  \`Word|StartTime|EndTime\`
- **Example:**  
  \`One|0.0|1.2 two|1.3|2.4 three|2.5|3.1\`

### Word Adjustment Rules
- **Merge:** Combine adjacent words, adjusting their times.
  - **Example:**  
    \`One|0.0|1.2 two|1.3|2.4 -> Onetwo|0.0|2.4\`
- **Split:** Break a word into multiple words, estimating new times.
  - **Example:**  
    \`Onetwothree|0.0|3.0 -> One|0.0|1.0 two|1.0|2.0 three|2.0|3.0\`

### Task Steps
1. **Parse Timecoded Text:** Break down the timecoded text into words with their timings.
2. **Process Lyrics:** Convert lyrics into a list of words, keeping line breaks.
3. **Correct Timecoded Text:**
   - Match words from lyrics to timecoded text.
   - Split or merge words to align with lyrics.
   - Try and replace words with the correct ones from lyrics.
4. **Integrate Line Breaks:** Apply line breaks from lyrics to the corrected timecoded text.
5. **Generate Output:** Produce the corrected timecoded text in the specified format.

### Output Requirements
- **Output:** Only the corrected timecoded text without code blocks or additional content.
- **Each word:** Should have a start and end time.

### Example

**Input:**

- **Timecoded Text:**  
  \`One|0.0|1.2 too|1.3|2.4 tree|2.5|3.1 four|3.2|4.0\`
- **Lyrics:**  
  \`One two\`  
  \`Three fourfive\`

**Expected Output:**
\`\`\`plaintext
One|0.0|1.2 two|1.3|2.4
Three|2.5|3.1 fourfive|3.2|4.0
\`\`\`

**Timecoded Words with Start and End Times:**
[words]

**Lyrics Text:**
[lyrics]`;

const SrtGeneratorWordsBatch = React.memo(
    ({open, closeModal, audioSrc, audioLength, audioFileNameProp, data, folderHandle}) => {
        const [showSrt, setShowSrt] = useLocalStorage('showSrt', false);
        const [openAiToken, setOpenAiToken] = useLocalStorage('openAiToken', '');
        const [audioFileName, setAudioFileName] = useState('');
        const [audioFile, setAudioFile] = useState(null);
        const [audioUrl, setAudioUrl] = useState(audioSrc || '');
        const audioFileHash = useHash(audioFile);
        const [lyrics, setLyrics] = useLocalStorage('lyrics_' + audioFileHash, '');
        const [hideLyrics, setHideLyrics] = useLocalStorage('hideLyrics', false);
        const [srt, setSrt] = useLocalStorage('srt_' + audioFileHash, '');
        const [srtData, setSrtData] = useLocalStorage('srtData_' + audioFileHash, '');
        const [wordsData, setWordsData] = useLocalStorage('wordsData_' + audioFileHash, '');
        const [enhancedSrt, setEnhancedSrt] = useLocalStorage('enhancedSrt_' + audioFileHash, '');
        const [loading, setLoading] = useState(false);
        const [statusMessage, setStatusMessage] = useState('');
        const [logMessages, setLogMessages] = useState([]);
        const [progress, setProgress] = useState(0);
        const [showLyrics, setShowLyrics] = useLocalStorage('showLyrics', false);
        const [streamingResponse, setStreamingResponse] = useState('');

        const wordSplitterRef = useRef(null);
        const srtRef = useRef(null);

        const audioRef = useRef(null);
        const [model, setModel] = useLocalStorage('model', 'gpt-4o');

        const [promptTemplate, setPromptTemplate] = useLocalStorage('promptTemplate', defaultPromptTemplate);

        useEffect(() => {
            // If data is set and audioFileHashProp, get the file from the folderHandle mp3 folder (if it exists) and then set the rest of the data as well
            console.log('data', data);
            console.log('audioFileNameProp', audioFileNameProp);
            if (data && audioFileNameProp) {
                const fetchData = async () => {
                    const folderHandleSub = await folderHandle.getDirectoryHandle('mp3', {create: true});

                    const files = await folderHandleSub.values();
                    for await (const file of files) {
                        if (file.name === audioFileNameProp) {
                            const fileData = await file.getFile();
                            const audioFileMp3 = new File([fileData], file.name, {
                                type: 'audio/mp3'
                            });

                            setAudioFile(audioFileMp3);
                            setAudioFileName(file.name);
                            setAudioUrl('');
                        }
                    }
                };

                fetchData();
            }
        }, [data, audioFileNameProp]);

        // On hash change if data is set
        useEffect(() => {
            if (data) {
                setWordsData(data.wordsData);
                setLyrics(data.lyrics);
                setSrt(data.srt);
                setSrtData(data.srtData);
                setEnhancedSrt(data.enhancedSrt);

                setStatusMessage('Data loaded from hash.');
                setProgress(100);
            }
        }, [audioFileHash]);

        const generateWordsJson = async () => {
            setLoading(true);
            setStatusMessage('Generating words JSON...');
            try {
                const formData = new FormData();
                formData.append('file', audioFile);
                formData.append('model', 'whisper-1');
                formData.append('response_format', 'verbose_json');
                formData.append('timestamp_granularities[]', 'word');

                // Language
                // formData.append('language', 'sv');

                const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
                    headers: {
                        Authorization: `Bearer ${openAiToken}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted * 0.8);
                    }
                });

                setStatusMessage('Words JSON generated successfully.');
                return response.data;
            } catch (error) {
                setStatusMessage(`Error: ${error.response?.data?.error || error.message}`);
                throw error;
            } finally {
                // setLoading(false);
            }
        };

        const enhanceLyrics = async (wordsData, lyrics) => {
            // If lyrics are empty, return stream that just returns the words data formatted
            if (!showLyrics || !lyrics) {
                const wordsText = wordsData.words.map(word => `${word.word}|${word.start}|${word.end}`).join(' ');

                // .choices[0]?.delta?.content || '';
                return {
                    [Symbol.asyncIterator]: async function* () {
                        yield {
                            choices: [
                                {
                                    delta: {
                                        content: roundStartAndEndTimes(wordsText)
                                    }
                                }
                            ]
                        };
                    }
                };
            }

            setLoading(true);
            setStatusMessage('Enhancing lyrics...');
            setProgress(0);
            const openai = new OpenAI({apiKey: openAiToken, dangerouslyAllowBrowser: true});
            try {
                const wordsText = wordsData.words.map(word => `${word.word}|${word.start}|${word.end}`).join(' ');
                const wordPrompt = promptTemplate
                    .replace(/\[words\]/g, roundStartAndEndTimes(wordsText).trim())
                    .replace(/\[lyrics\]/g, prepareLyrics(lyrics).trim());

                const messages = [
                    {
                        role: 'system',
                        content: 'You are a helpful assistant that corrects a transcribed text.'
                    },
                    {
                        role: 'user',
                        content: wordPrompt
                    }
                ];

                window.logger('messages', <pre>{JSON.stringify(messages, null, 2)}</pre>);
                const stream = await openai.chat.completions.create({
                    model: model,
                    messages: messages,
                    stream: true
                });

                /*let fullResponse = '';
            for await (const chunk of stream) {
                const content = chunk.choices[0]?.delta?.content || '';
                fullResponse += content;
                setStreamingResponse(prev => prev + content);
            }

            setStatusMessage('Lyrics enhanced successfully.');
            return fullResponse;*/

                return stream;
            } catch (error) {
                setStatusMessage(`Error enhancing lyrics: ${error.message}`);
                throw error;
            }
        };
        const srtTextToRealSrt = (content, extendLines = false) => {
            const lines = content.split('\n');
            let srtContent = '';
            let subtitleIndex = 1;
            let nextStartTime;

            lines.forEach((line, index) => {
                const words = line.split(' ').filter(word => word.length > 0);
                if (words.length === 0) {
                    return;
                }
                const firstWord = words[0].split('|');
                const lastWord = words[words.length - 1].split('|');

                const startTime = parseFloat(firstWord[1]);
                let endTime = parseFloat(lastWord[2]);

                // If extendLines is true and it's not the last line,
                // set endTime to the start time of the next line
                if (extendLines && index < lines.length - 1) {
                    const nextLine = lines[index + 1];
                    const nextWords = nextLine.split(' ').filter(word => word.length > 0);
                    if (nextWords.length > 0) {
                        const nextFirstWord = nextWords[0].split('|');
                        nextStartTime = parseFloat(nextFirstWord[1]);
                        endTime = nextStartTime;
                    }
                }

                const textContent = words.map(word => word.split('|')[0]).join(' ');

                srtContent += `${subtitleIndex}\n`;
                srtContent += `${formatTime(startTime)} --> ${formatTime(endTime)}\n`;
                srtContent += `${textContent}\n\n`;

                subtitleIndex++;
            });

            return srtContent;
        };

        useEffect(() => {
            if (srt) {
                setSrtData(srtTextToRealSrt(srt, true));
            }
        }, [srt]);

        const handleGenerateSrt = async (withLyrics = false, wordsDataInput) => {
            if (!wordsDataInput) {
                wordsDataInput = wordsData;
            }

            if (!wordsDataInput || !wordsDataInput.words) {
                if (!audioFile) {
                    setStatusMessage('No audio file selected.');
                    return;
                }

                try {
                    setLoading(true);
                    let words = wordsDataInput;
                    if (!words || !words.words) {
                        words = await generateWordsJson();

                        setWordsData(words);

                        handleGenerateSrt(withLyrics, words);
                    }
                } catch (error) {
                    setStatusMessage(`Error generating SRT: ${error.message}`);
                } finally {
                    setLoading(false);
                    setProgress(0);
                }
            } else {
                let enhancedWordsText = wordsDataInput.words
                    .filter(word => {
                        return word.word.indexOf('```') === -1 && word.word.length > 0;
                    })
                    .map(word => `${word.word}|${word.start}|${word.end}`)
                    .join(' ')
                    .trim();

                const updateSrt = () => {
                    /*let enhancedWords = enhancedWordsText.split(' ');
                let enhancedWordsTextFormatted = '';
                let lastWordEndTime = 0;
                for (let i = 0; i < enhancedWords.length; i++) {
                    if (enhancedWords[i].length === 0 || enhancedWords[i] === ' ') {
                        continue;
                    }
                    const word = enhancedWords[i];
                    const [wordText, startTime, endTime] = word.split('|');
                    if (i === 0) {
                        enhancedWordsTextFormatted += `${wordText}|${startTime}|${endTime} `;
                        lastWordEndTime = parseFloat(endTime);
                        continue;
                    }

                    if (parseFloat(startTime) > lastWordEndTime) {
                        enhancedWordsTextFormatted += '\n';
                    }


                    enhancedWordsTextFormatted += `${wordText}|${startTime}|${endTime} `;
                    lastWordEndTime = parseFloat(endTime);
                }*/

                    setSrt(enhancedWordsText);
                };

                let preparedLyrics = lyrics;
                setStreamingResponse(''); // Reset streaming response
                setLoading(true);

                if (withLyrics) {
                    const enhancedWordsTextStream = await enhanceLyrics(wordsDataInput, preparedLyrics);

                    // Use original words data to calculate total time estimate
                    const approxTotal = wordsDataInput.words.length;

                    enhancedWordsText = '';
                    for await (const chunk of enhancedWordsTextStream) {
                        const content = chunk.choices[0]?.delta?.content || '';
                        enhancedWordsText += content;
                        enhancedWordsText = enhancedWordsText.replace(/```.*?\n?/g, ''); // Remove code blocks
                        updateSrt();

                        const progressEstimate = Math.round((enhancedWordsText.split(' ').length / approxTotal) * 100);
                        // 123/456 example
                        const progressLabel = `${enhancedWordsText.split(' ').length}/${approxTotal}`;
                        setProgress(progressEstimate);
                        setStatusMessage(`Enhancing lyrics... ${progressLabel}`);

                        // Scroll to bottom
                        if (wordSplitterRef.current) {
                            // wordSplitterRef.current.scrollTop = wordSplitterRef.current.scrollHeight;
                            // scroll
                            smoothScrollToBottom(wordSplitterRef.current);
                        }
                        if (srtRef.current) {
                            srtRef.current.scrollTop = srtRef.current.scrollHeight;
                        }
                    }

                    setStatusMessage('Lyrics enhanced successfully.');
                    setLoading(false);
                } else {
                    updateSrt();
                }
                setLoading(false);

                //console.log('enhancedWordsTextFormatted', enhancedWordsTextFormatted);
                setProgress(100);
                setStatusMessage('SRT generated successfully.');
            }
        };

        useEffect(() => {
            window.addEventListener('use-audio', e => {
                setAudioUrl(e.detail);
            });
        }, []);

        const audioPlayer = useMemo(() => {
            return (
                audioFile && (
                    <>
                        {/*<p>
                        <strong>
                            {audioFile.name} | {Math.round(audioFile.size / 1024 / 1024)} MB
                        </strong>
                    </p>
                    {audioFileHash}*/}
                        <audio
                            key={audioFile.name}
                            ref={audioRef}
                            controls
                            src={URL.createObjectURL(audioFile)}
                            style={{
                                width: '100%'
                            }}
                            onTimeUpdate={e => {
                                window.dispatchEvent(
                                    new CustomEvent('audio-time-update', {
                                        detail: e.target.currentTime
                                    })
                                );
                            }}
                        />
                    </>
                )
            );
        }, [audioFile]);

        useEffect(() => {
            // Set mp3Cache[hash] to audioFile
            if (audioFile && audioFileHash) {
                mp3Cache[audioFileHash] = audioFile;
            }
        }, [audioFile, audioFileHash]);

        const loadAudio = async () => {
            if (progress > 0) {
                return;
            }

            setProgress(1);

            if (!audioUrl) {
                setStatusMessage('No audio url entered.');
                return;
            }

            setLoading(true);
            setStatusMessage('Downloading audio...');
            try {
                let audioUrlFixed = audioUrl;
                if (audioUrlFixed.indexOf('dropbox.com') !== -1) {
                    audioUrlFixed = audioUrlFixed.replace('www.dropbox.com', 'dl.dropbox.com');
                    audioUrlFixed = audioUrlFixed.replace('?dl=0', '?raw=1');
                }

                const response = await axios.get(audioUrlFixed, {
                    responseType: 'arraybuffer',
                    onDownloadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted * 0.2);
                    }
                });

                const hash = await crypto.subtle.digest('SHA-256', new Uint8Array(response.data));
                const hashArray = Array.from(new Uint8Array(hash));

                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                const name = hashHex + '.mp3';

                if (mp3Cache[name]) {
                    setAudioFile(await mp3Cache[name]);
                    setAudioFileName(name);
                    setStatusMessage(null);
                    setProgress(0);
                    return;
                }

                if (folderHandle) {
                    const folderHandleSub = await folderHandle.getDirectoryHandle('mp3', {create: true});

                    const files = await folderHandleSub.values();
                    for await (const file of files) {
                        if (file.name === name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3') {
                            const data = await file.getFile();
                            const audioFileMp3 = new File([data], file.name, {
                                type: 'audio/mp3'
                            });

                            mp3Cache[name] = audioFileMp3;
                            setAudioFile(audioFileMp3);
                            setAudioFileName(file.name);
                            setStatusMessage(null);
                            setProgress(0);

                            return;
                        }
                    }
                }

                const url = new URL(audioUrlFixed);
                let filename = url.pathname.split('/').pop();
                filename = filename.split('?')[0];

                const audioFile = new File([response.data], filename, {
                    type: response.headers['content-type']
                });

                setStatusMessage('Audio downloaded! Converting to mp3... ' + audioLength + 's');

                const total = audioLength;

                convertToMp3(audioFile, audioLength, folderHandle, name, async message => {
                    if (!message.includes('size=')) {
                        return;
                    }

                    let current = message.match(/time=(.*?) bitrate/)?.[1];
                    current = moment(current, 'HH:mm:ss.SS').diff(moment().startOf('day'), 'seconds');
                    const progress = (parseFloat(current) / parseFloat(total)) * 100;
                    console.log('progress', current, total, progress);
                    if (progress) {
                        setProgress(20 + parseFloat(progress) * 0.8);
                    }

                    setLogMessages(prev => [message]);
                }).then(audioFile => {
                    if (!audioFile) {
                        return;
                    }
                    setAudioFile(audioFile);
                    setStatusMessage(null);
                    setAudioFileName(audioFile.name);
                    setProgress(0);
                    setLogMessages([]);
                });
            } catch (error) {
                setStatusMessage(`Error: ${error.response?.data?.error || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            if (audioRef.current) {
                audioRef.current.pause();
            }

            if (audioSrc) {
                setAudioUrl(audioSrc);
                setAudioFile(null);
                loadAudio();
            }
        }, [audioSrc]);

        const progressBar = (
            <>
                <Progress value={progress} mt={4} colorScheme="green" />
                <ProgressLabel loading={loading}>{statusMessage}</ProgressLabel>
            </>
        );

        return (
            <Modal size="3xl" isOpen={open} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generate Srt</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <AccessTokenInput value={openAiToken} onChange={e => setOpenAiToken(e.target.value)} />

                        <a
                            href="https://platform.openai.com/api-keys"
                            target="_blank"
                            style={{
                                marginTop: '10px',
                                display: 'block',
                                color: 'hsl(219 19% 79% / 1)'
                            }}
                        >
                            Get OpenAI API Key from the OpenAI Platform >>
                        </a>

                        {openAiToken && (
                            <>
                                {(audioFile || (audioUrl.length > 0 && audioUrl.match(/\.(mp3|wav|ogg)$/))) && (
                                    <>
                                        <hr />
                                        {audioFileName}: <br />
                                        {audioPlayer}
                                        <hr />
                                    </>
                                )}

                                {!audioSrc && !audioFile && (
                                    <>
                                        <MultiTypeInput
                                            fileName={audioFileName}
                                            url={audioUrl}
                                            onFile={e => {
                                                setAudioFile(e.target.files[0]);
                                                setAudioUrl('');
                                            }}
                                            onUrl={e => {
                                                setAudioUrl(e.target.value);
                                                setAudioFile(null);
                                            }}
                                        >
                                            <legend>
                                                Enter url instead for audio file (dropbox, google drive, etc.):
                                            </legend>
                                        </MultiTypeInput>
                                        <hr />

                                        <Button onClick={loadAudio}>Load Audio</Button>
                                        <hr />
                                    </>
                                )}
                            </>
                        )}

                        <PromptEditor
                            promptTemplate={promptTemplate}
                            setPromptTemplate={setPromptTemplate}
                            wordsData={wordsData}
                            lyrics={lyrics}
                        />

                        <ButtonGroup mt={4} display="flex" justifyContent="flex-start" flexWrap="wrap" mx={-2}>
                            <Button
                                onClick={() => {
                                    // Show confirmation warning
                                    if (srtData.length > 0) {
                                        if (
                                            !window.confirm(
                                                'Are you sure you want to clear the SRT data and use the original transcription?'
                                            )
                                        ) {
                                            return;
                                        }
                                    }
                                    setShowLyrics(false);
                                    setHideLyrics(true);
                                    handleGenerateSrt(false);
                                }}
                                mx={2}
                                colorScheme="purple"
                                style={
                                    showLyrics
                                        ? {
                                              // Not selected, so grayscale and fade opacity some
                                              filter: 'grayscale(100%)',
                                              opacity: 0.5
                                          }
                                        : {}
                                }
                            >
                                Generate SRT without lyrics
                            </Button>
                            <Button
                                onClick={() => {
                                    // Show confirmation warning
                                    if (srtData.length > 0) {
                                        if (
                                            !window.confirm(
                                                'Are you sure you want to clear the SRT data and generate a new SRT enhanced with lyrics?'
                                            )
                                        ) {
                                            return;
                                        }
                                    }
                                    setShowLyrics(true);
                                    setHideLyrics(false);
                                    // Clear everything
                                    setSrt('');
                                    setEnhancedSrt('');
                                    setSrtData('');
                                }}
                                mx={2}
                                colorScheme="purple"
                                style={
                                    !showLyrics
                                        ? {
                                              // Not selected, so grayscale and fade opacity some
                                              filter: 'grayscale(100%)',
                                              opacity: 0.5
                                          }
                                        : {}
                                }
                            >
                                Generate SRT with lyrics
                            </Button>
                            <ModelSelector model={model} setModel={setModel} openAiToken={openAiToken} />
                        </ButtonGroup>

                        {srt && srt.length > 0 && (
                            // Regenerate SRT with lyrics (from whisper)
                            <ButtonGroup>
                                <Button
                                    onClick={() => {
                                        setSrt('');
                                        setEnhancedSrt('');
                                        setSrtData('');
                                        setWordsData('');

                                        // Close
                                        setTimeout(() => {
                                            closeModal();
                                        }, 100);
                                    }}
                                    mt={4}
                                    colorScheme="gray"
                                >
                                    Clear SRT data
                                </Button>
                            </ButtonGroup>
                        )}

                        {showLyrics && (
                            <CustomBox my={4}>
                                <AnimatePresence mode="wait">
                                    {showLyrics && !hideLyrics ? (
                                        <>
                                            <TextareaDebounced
                                                key="lyrics"
                                                value={lyrics}
                                                onInput={e => {
                                                    setLyrics(e.target.value);
                                                }}
                                                placeholder="Enter lyrics here..."
                                                rows={10}
                                                style={{
                                                    background: 'white'
                                                }}
                                            />
                                            <Button
                                                key="hideLyrics"
                                                as={motion.button}
                                                initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                exit={{opacity: 0}}
                                                onClick={() => {
                                                    setHideLyrics(true);
                                                }}
                                                mt={2}
                                                colorScheme="gray"
                                            >
                                                Hide Lyrics
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            key="editLyrics"
                                            as={motion.button}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            onClick={() => {
                                                setHideLyrics(false);
                                            }}
                                            colorScheme="gray"
                                            mt={2}
                                        >
                                            Edit Lyrics
                                        </Button>
                                    )}
                                </AnimatePresence>
                                <Button
                                    onClick={() => {
                                        handleGenerateSrt(true);
                                        setHideLyrics(true);
                                    }}
                                    mt={2}
                                    colorScheme="green"
                                >
                                    Generate with Lyrics
                                </Button>
                            </CustomBox>
                        )}

                        {/* Special text modifications */}
                        <Select
                            mt={4}
                            onChange={e => {
                                console.log('e.target.value', e.target.value);

                                const words = srt.replace(/\n/g, ' ').split(' ');

                                switch (e.target.value) {
                                    case 'break-on-each-word':
                                        // use srt state
                                        setSrt(words.join('\n'));
                                        break;
                                    case 'split-every-5-words':
                                        // use srt state
                                        const wordsSplitNew = [];
                                        for (let i = 0; i < words.length; i += 5) {
                                            wordsSplitNew.push(words.slice(i, i + 5).join(' '));
                                        }
                                        setSrt(wordsSplitNew.join('\n'));
                                        break;
                                    case 'remove-single-word-line-breaks':
                                        // Get current srt, then replace \nsingleword\n with singleword\n
                                        setSrt(srt.replace(/\n([^\n ]+)\n/g, ' $1\n'));
                                        break;
                                    case 'break-by-space-between-words':
                                        // Get current srt, insert new lines when a words endTime is less than the next words startTime
                                        const wordsNew = [];
                                        for (let i = 0; i < words.length; i++) {
                                            const [word, start, end] = words[i].split('|');
                                            const nextWord = words[i + 1] ? words[i + 1].split('|') : null;
                                            if (nextWord && parseFloat(end) < parseFloat(nextWord[1])) {
                                                wordsNew.push(`${word}|${start}|${end}\n`);
                                            } else {
                                                wordsNew.push(`${word}|${start}|${end} `);
                                            }
                                        }
                                        setSrt(wordsNew.join(''));
                                        break;
                                    case 'break-by-capital-letters':
                                        // Get current srt, then replace all capital letters with \nCapitalLetter (with ÅÄÖ)
                                        setSrt(srt.replace(/([A-ZÅÄÖ])/g, '\n$1'));
                                        break;
                                    case 'remove-line-breaks':
                                        // Get current srt, then replace \nsingleword\n with singleword\n
                                        setSrt(srt.replace(/\n/g, ''));
                                        break;
                                    default:
                                        break;
                                }

                                e.target.value = '';
                            }}
                        >
                            <option value="">Select special text modifications...</option>
                            {/* Break on each word (removes all breaks and inserts new lines) */}
                            <option value="break-on-each-word">Break on each word</option>
                            {/* Split every 5 words */}
                            <option value="split-every-5-words">Split every 5 words</option>
                            {/* Remove single word line breaks */}
                            <option value="remove-single-word-line-breaks">Remove single word line breaks</option>
                            {/* Break by space between words */}
                            <option value="break-by-space-between-words">Break by space between words</option>

                            <option value="break-by-capital-letters">Break by capital letters</option>
                            {/* Remove all line breaks */}
                            <option value="remove-line-breaks">Remove all line breaks</option>
                        </Select>

                        {progressBar}

                        {showSrt && srt && srt.length > 0 && (
                            // Show the original words and timings
                            <Textarea
                                value={srt}
                                onChange={e => setSrt(e.target.value)}
                                placeholder="SRT data..."
                                rows={10}
                                mt={4}
                            />
                        )}

                        {srt && srt.length > 0 && (
                            <WordSplitter
                                ref={wordSplitterRef}
                                disableHistory={loading}
                                words={srt}
                                onChange={newSrt => {
                                    console.log('newSrt', newSrt);
                                    setSrt(newSrt);
                                }}
                                onClear={() => {
                                    setSrt('');
                                    setEnhancedSrt('');
                                    setSrtData('');
                                }}
                            />
                        )}

                        {showSrt && srtData && (
                            <Textarea
                                ref={srtRef}
                                value={srtData}
                                onChange={e => setSrtData(e.target.value)}
                                placeholder="SRT data..."
                                rows={10}
                                mt={4}
                            />
                        )}

                        {srtData && (
                            <>
                                <Button
                                    onClick={() => {
                                        window.dispatchEvent(
                                            new CustomEvent('use-srt', {
                                                detail: {
                                                    srt: srtData,
                                                    audioFileName: audioFileName,
                                                    // Add other data for future editing
                                                    data: {
                                                        lyrics,
                                                        hideLyrics,
                                                        srt,
                                                        srtData,
                                                        wordsData,
                                                        enhancedSrt
                                                    }
                                                }
                                            })
                                        );
                                        closeModal && closeModal();
                                    }}
                                    colorScheme="green"
                                    mt={4}
                                >
                                    Add SRT to project
                                </Button>
                                <Button
                                    onClick={() => {
                                        triggerDownload(srtData, 'srt', 'srt');
                                    }}
                                    colorScheme="gray"
                                    mt={4}
                                >
                                    Download SRT
                                </Button>
                                <Button
                                    onClick={() => {
                                        setShowSrt(!showSrt);
                                    }}
                                    colorScheme="gray"
                                    mt={4}
                                >
                                    {showSrt ? 'Hide SRT' : 'Show SRT'}
                                </Button>
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        );
    }
);

// Syntax highlighted editable text for lyrics
/*
Example of content:
[Pre-Intro, Spoken]

[Verse]
Yo, it's Pasta Menace, the king of the grain
A.K.A. Pastell-Cocaine-ell, spittin' fire like a propane

[Verse]
...
*/
const LyricsEditableText = ({value, onChange, ...props}) => {
    return (
        <CustomBox boxShadow="xl" p="6" rounded="md" bg="white" my={4} borderWidth="4px">
            <legend>
                <strong>Add lyrics to enhance generated Srt</strong>
            </legend>
            <LyricsContent
                placeholder="Add lyrics here..."
                onInput={e => {
                    onChange(e.target.value);
                }}
                autoFocus
                value={value}
                {...props}
            />
        </CustomBox>
    );
};

// Textarea that automatically resizes when text is added
const AutosizeTextarea = React.forwardRef(({...props}, ref) => {
    const localRef = useRef(null);

    useEffect(() => {
        if (!ref) return;
        ref.current = localRef.current;
    }, [ref]);

    useEffect(() => {
        if (!localRef.current) {
            return;
        }

        const textarea = localRef.current;
        const resize = () => {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        };

        textarea.addEventListener('input', resize);
        resize();

        return () => {
            textarea.removeEventListener('input', resize);
        };
    }, [ref]);

    return <textarea ref={localRef} {...props} />;
});

const LyricsContent = styled(AutosizeTextarea)`
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    min-height: 100%;
    width: 100%;
    resize: vertical;
    overflow: auto;
    white-space: pre-wrap;
    background: white;
    color: black;
    outline: none;

    max-height: 30vh;

    span {
        color: red;

        span {
            color: blue;
        }
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;

        margin: 20px -10px;

        /*div {
            margin: 20px;
        }*/
    }

    // <Row direction="column" gap={2}
    ${props =>
        typeof props.direction === 'string' &&
        css`
            flex-direction: ${props.direction} !important;
        `}

    ${props =>
        typeof props.gap === 'number' &&
        css`
            > * {
                margin: ${props.gap}px;
            }
        `}
`;

const Column = styled(motion.div)`
    flex: 1;
    // Break long lines
    pre {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    margin: 10px;

    // sm prop contains styles to be applied on small screens
    ${props =>
        props.sm &&
        css`
            @media (max-width: 768px) {
                ${props.sm}
            }
        `}
`;

const SyncedScrollColumn = ({syncId, children, contentId, scrollToBottomOnUpdate, title, ...props}) => {
    const colRef = useRef(null);

    // When one column scrolls, sync the scroll position of the other column
    const handleScroll = e => {
        const {target} = e;
        const scroll = target.scrollTop;
        const otherColumns = [...document.querySelectorAll(`[data-sync-id="${syncId}"]`)];
        const otherColumn = otherColumns.find(column => column !== target);
        if (!otherColumn) {
            return;
        }
        otherColumn.scrollTop = scroll;
    };

    useEffect(() => {
        if (!scrollToBottomOnUpdate) {
            return;
        }
        colRef.current.scrollTop = colRef.current.scrollHeight;
    }, [scrollToBottomOnUpdate, contentId]);

    return (
        <Column {...props}>
            <CustomBox boxShadow="xl" p="6" rounded="md" bg="white" borderWidth="4px">
                {title && (
                    <legend>
                        <strong>{title}</strong>
                    </legend>
                )}
                <div
                    ref={colRef}
                    data-sync-id={syncId}
                    onScroll={handleScroll}
                    className="synced-scroll-column"
                    style={{
                        padding: '10px',
                        height: '400px',
                        borderRadius: '5px',
                        overflow: 'auto',
                        // Mobile scroll smooth
                        overflowScrolling: 'touch'
                    }}
                >
                    {children}
                </div>
            </CustomBox>
        </Column>
    );
};

const DiffToSource = ({newVal, oldVal, color, onChanged, audioRef}) => {
    const [activeLine, setActiveLine] = useState(0);

    useEffect(() => {
        const handleAudioTimeUpdate = e => {
            const currentTime = e.detail;
            const lines = newVal.split('\n\n');
            /*
            Example:
            0
            : 
            "1\n00:00:00,000 --> 00:00:02,000\nNice"
            1
            : 
            "2\n00:00:25,959 --> 00:00:27,959\nThey think that's"
            2
            : 
            "3\n00:00:30,000 --> 00:00:32,580\nSilence is gold that money brings happiness"
            3
            : 
            "4\n00:00:33,880 --> 00:00:35,880\nBut happiness cancels"
            4
            : 
            "5\n00:00:36,400 --> 00:00:41,680\nI live my life at the o
            */
            let lineIndex = 0;
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                const time = line.match(/(\d{2}:\d{2}:\d{2},\d{3})/g);
                if (!time) {
                    continue;
                }
                const [start, end] = time;
                // Get starttime and endtime in seconds (they are timestamps starting from 00:00:00,000) Example: 00:00:02,000
                const startTime = moment(start, 'HH:mm:ss,SSS').diff(moment().startOf('day'), 'seconds');
                const endTime = moment(end, 'HH:mm:ss,SSS').diff(moment().startOf('day'), 'seconds');
                if (currentTime >= startTime) {
                    lineIndex = i;
                }
            }
            setActiveLine(lineIndex + 1);
        };

        window.addEventListener('audio-time-update', handleAudioTimeUpdate);

        return () => {
            window.removeEventListener('audio-time-update', handleAudioTimeUpdate);
        };
    }, []);

    const diffed = useMemo(() => {
        if (!newVal) {
            return oldVal;
        }

        // Compare each line, and each word in each line
        const newLines = newVal.split('\n');
        const oldLines = oldVal.split('\n');
        let lastNewLine = 0;
        const diffedLines = newLines.map((newLine, index) => {
            const oldLine = oldLines[index];

            // If old line is missing, dont diff
            if (!oldLine) {
                return newLine;
            }

            const newWords = newLine ? newLine.split(' ') : [];
            const oldWords = oldLine ? oldLine.split(' ') : [];
            const diffedWords = newWords.map((newWord, index) => {
                const oldWord = oldWords[index];
                const diffedWord = newWord === oldWord ? newWord : `<span class="changed-srt">${newWord}</span>`;
                return diffedWord;
            });
            let lineAttr = '';

            // If line is only a number, add data-line attribute
            if (newLine.match(/^\d+$/)) {
                lastNewLine = newLine;
            }

            lineAttr = `data-line="${lastNewLine}"`;

            return `<span ${lineAttr} style="display: inline-block;">${diffedWords.join(' ')}</span>`;
        });
        return diffedLines.join('\n');
    }, [newVal, oldVal]);

    useEffect(() => {
        // Find the active line, and scroll to it smoothly, and highlight it
        const line = [...document.querySelectorAll(`[data-line="${activeLine}"]`)];
        console.log('line', line);
        if (line.length) {
            [...document.querySelectorAll(`[data-line]`)].forEach(el => {
                el.style.background = 'none';

                const syncedContainer = closest(el, '[data-sync-id]');
                if (syncedContainer && syncedContainer.scrolling) {
                    clearInterval(syncedContainer.scrolling);
                    syncedContainer.scrolling = null;
                }
            });
            line.forEach(el => {
                el.style.background = 'yellow';

                const syncedContainer = closest(el, '[data-sync-id]');
                if (syncedContainer) {
                    console.log('syncedContainer', syncedContainer);
                    console.log('line[0]', el, el.offsetTop, syncedContainer.scrollTop, syncedContainer.offsetTop);
                    // syncedContainer.scrollTop = el.offsetTop - 200;
                    const target = el.offsetTop;
                    const duration = 200;
                    const startTime = Date.now();
                    const start = syncedContainer.scrollTop;

                    if (!syncedContainer.scrolling) {
                        syncedContainer.scrolling = setInterval(() => {
                            const time = Date.now() - startTime;
                            const progress = Math.min(time / duration, 1);
                            syncedContainer.scrollTop = start + (target - start) * progress;
                            if (progress === 1) {
                                clearInterval(syncedContainer.scrolling);
                                syncedContainer.scrolling = null;
                            }
                        }, 1000 / 60);
                    }
                }
            });
        }
    }, [activeLine]);

    return (
        <DiffContainer>
            {/*<div
                style={{
                    position: 'sticky',
                    top: '-10px',
                    padding: '10px 0',
                    margin: '-10px',
                    background: 'white',
                    border: '1px solid black',
                    borderRadius: '5px',
                    color: 'black',

                }}
            >
                {activeLine > 0 && <p>Active line: {activeLine}</p>}
            </div>*/}
            <pre
                style={{
                    '--color': color,
                    color: 'rgba(0, 0, 0, 0.5)',
                    fontSize: '0.8em'
                }}
                dangerouslySetInnerHTML={{__html: diffed}}
                contentEditable={true}
                onBlur={e => {
                    onChanged && onChanged(e.target.innerText);
                    console.log('onBlur', e.target.innerText);

                    if (audioRef.current) {
                        try {
                            //audioRef.current.play();
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }}
                onFocus={e => {
                    if (audioRef.current) {
                        audioRef.current.pause();
                    }
                }}
            />
        </DiffContainer>
    );
};

const DiffContainer = styled.div`
    .changed-srt {
        color: var(--color);
        font-weight: bold;
    }
`;

const StatusMessage = styled(motion.div)`
    color: #fff;
    font-weight: bold;
    position: fixed;
    top: 20px;
    left: 50%;
    padding: 10px;

    max-width: 80vw;

    // Green
    background: #4caf50;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    transform: translateX(-50%);
    z-index: 2000;
`;

const ModelSelector = ({openAiToken, model, setModel, ...props}) => {
    const [models, setModels] = useLocalStorage('models', []);
    const [loading, setLoading] = useState(false);
    const [lastTime, setLastTime] = useLocalStorage('lastTime', 0);

    useEffect(() => {
        if (!openAiToken) {
            return;
        }

        // If it has been at least 4 hours since last time, refresh models
        if (Date.now() - lastTime > 1000 * 60 * 60 * 4) {
            setLoading(true);
            axios
                .get('https://api.openai.com/v1/models', {
                    headers: {
                        Authorization: `Bearer ${openAiToken}`
                    }
                })
                .then(response => {
                    setModels(response.data.data.filter(model => model.id.startsWith('gpt-4')));
                    setLastTime(Date.now());
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [openAiToken]);

    return (
        models.length > 0 && (
            <span style={{marginLeft: 'auto'}}>
                <Select
                    bg="#d6bef8"
                    color="black"
                    _hover={{
                        bg: '#bfa0f6'
                    }}
                    _css={{
                        pointerEvents: loading ? 'none' : 'auto'
                    }}
                    // Lower width to fit in modal
                    w="150px"
                    value={model}
                    onChange={e => {
                        setModel(e.target.value);
                    }}
                    {...props}
                >
                    {models.map(model => (
                        <option key={model.id} value={model.id}>
                            {model.id}
                        </option>
                    ))}
                </Select>
            </span>
        )
    );
};

export {Row, Column};

// Error Boundary Component
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }

    render() {
        if (this.state.hasError) {
            return <h1>Error: {this.state.error.message}</h1>;
        }
        return this.props.children;
    }
}

const SrtGeneratorWordsBatchWIthErrorBoundary = props => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
                <SrtGeneratorWordsBatch {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};

export default SrtGeneratorWordsBatch;
