import React, {useContext, createContext, useState, useEffect} from 'react';
import {Box} from '@chakra-ui/react';

const DebugContext = createContext({snappedTo: 'none'});

export const useDebugContext = () => useContext(DebugContext);

const Layout = ({children}) => {
    const [debugState, setDebugState] = useState({snappedTo: 'none', snappedWidth: 0, snappedHeight: 0});

    useEffect(() => {
        // Set body --offset-top, --offset-bottom, --offset-left, --offset-right
        const body = document.querySelector('body');
        body.style.setProperty('--offset-top', debugState.snappedTo === 'top' ? debugState.snappedHeight + 'px' : '0');
        body.style.setProperty(
            '--offset-bottom',
            debugState.snappedTo === 'bottom' ? debugState.snappedHeight + 'px' : '0'
        );
        body.style.setProperty('--offset-left', debugState.snappedTo === 'left' ? debugState.snappedWidth + 'px' : '0');
        body.style.setProperty(
            '--offset-right',
            debugState.snappedTo === 'right' ? debugState.snappedWidth + 'px' : '0'
        );
    }, [debugState]);

    return (
        <DebugContext.Provider value={{...debugState, setDebugState}}>
            <Box
                pl={debugState.snappedTo === 'left' ? debugState.snappedWidth : '0'}
                pr={debugState.snappedTo === 'right' ? debugState.snappedWidth : '0'}
                pt={debugState.snappedTo === 'top' ? debugState.snappedHeight : '0'}
                pb={debugState.snappedTo === 'bottom' ? debugState.snappedHeight : '0'}
            >
                {children}
            </Box>
        </DebugContext.Provider>
    );
};

export default Layout;
