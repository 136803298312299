import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Box, Button, VStack, HStack, Text, List, ListItem, useColorModeValue, Select} from '@chakra-ui/react';
import {FiChevronUp, FiChevronDown, FiX, FiTrash2, FiMaximize} from 'react-icons/fi';
import {motion} from 'framer-motion';

import styled, {css} from 'styled-components';

import {useDebugContext} from './MainLayout.js';

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

const LoggerContainer = styled(motion.div)`
    // Dark blue
    position: fixed;
    background-color: ${props => props.bg};
    border-width: 1px;
    border-color: ${props => props.borderColor};
    border-radius: md;
    box-shadow: lg;
    z-index: 9999;
    overflow: hidden;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 1 !important;
    }
`;

const DebugLogger = () => {
    const {debugState, setDebugState} = useDebugContext();
    const [active, setActive] = useState(() => window.location.hash === '#debug');
    const [log, setLog] = useState([]);
    const [logSize] = useLocalStorage('logSize', 10);
    const [collapsed, setCollapsed] = useLocalStorage('logCollapsed', false);
    const [position, setPosition] = useLocalStorage('logPosition', {x: 20, y: 20});
    const [size, setSize] = useLocalStorage('logDimensions', {width: 400, height: 300});
    const [snappedTo, setSnappedTo] = useLocalStorage('logSnappedTo', 'none');

    const positionRef = useRef(position);
    const sizeRef = useRef(size);

    const loggerRef = useRef(null);

    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const headerBgColor = useColorModeValue('gray.100', 'gray.700');

    useEffect(() => {
        positionRef.current = position;
        sizeRef.current = size;
    }, [position, size]);

    const addLogMessage = useCallback(
        (id, name, msg) => {
            if (!msg || msg.length === 0) {
                // Assume id and name is same (id) and name is the actual message
                msg = name;
                name = id;
            }
            setLog(prev => {
                const idx = prev.findIndex(log => log.id === id.current);
                const newLog = {id: id.current, name, msg};
                if (idx === -1) {
                    return [newLog, ...prev].slice(0, logSize);
                }
                const newArr = [newLog, ...prev.slice(0, idx), ...prev.slice(idx + 1)];
                newArr.sort((a, b) => a.name.localeCompare(b.name));
                return newArr;
            });
        },
        [logSize]
    );

    useEffect(() => {
        if (window.location.hash === '#debug') {
            setDebugState(prev => ({
                ...prev,
                snappedTo: snappedTo,
                snappedWidth: size.width,
                snappedHeight: size.height
            }));
        }
    }, [snappedTo, size, setDebugState]);

    useEffect(() => {
        if (active) {
            window.logger = addLogMessage;
        } else {
            window.logger = () => {};
        }
        return () => {
            window.logger = () => {};
        };
    }, [active, addLogMessage]);

    const clearLog = () => setLog([]);
    const toggleActive = () => setActive(prev => !prev);
    const toggleCollapsed = () => setCollapsed(prev => !prev);

    const handleSnap = useCallback(
        (newPosition, newSize) => {
            const snapThreshold = 20; // pixels from edge to trigger snap
            const {innerWidth, innerHeight} = window;
            let snapPosition = 'none';

            if (newPosition.x <= snapThreshold) {
                newPosition.y = 0;
                newPosition.x = 0;
                // newSize.width = 400;
                newSize.height = innerHeight;
                snapPosition = 'left';
            } else if (newPosition.x + newSize.width >= innerWidth - snapThreshold) {
                newPosition.y = 0;
                newPosition.x = innerWidth - 400;
                // newSize.width = 400;
                newSize.height = innerHeight;
                snapPosition = 'right';
            } else if (newPosition.y <= snapThreshold) {
                newPosition.y = 0;
                newPosition.x = 0;
                newSize.width = innerWidth;
                // newSize.height = 300;
                snapPosition = 'top';
            } else if (newPosition.y + newSize.height >= innerHeight - snapThreshold) {
                newPosition.y = innerHeight - 300;
                newPosition.x = 0;
                newSize.width = innerWidth;
                // newSize.height = 300;
                snapPosition = 'bottom';
            }

            setPosition(newPosition);
            setSize(newSize);
            setSnappedTo(snapPosition);
        },
        [setPosition, setSize, setSnappedTo]
    );

    const handleMouseDown = useCallback(
        e => {
            e.preventDefault();
            let startX = e.clientX - position.x;
            let startY = e.clientY - position.y;

            // If not already float
            if (snappedTo !== 'none') {
                setPosition({x: e.clientX - startX, y: e.clientY - startY});
                setSize({
                    width: 400,
                    height: 300
                });
                setSnappedTo('none');

                const handleMouseMove = e => {
                    const newPosition = {
                        x: e.clientX - startX,
                        y: e.clientY - startY
                    };
                    setPosition(newPosition);
                };

                const handleMouseUp = () => {
                    document.removeEventListener('mousemove', handleMouseMove);
                    document.removeEventListener('mouseup', handleMouseUp);
                };

                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);

                return;
            }

            const handleMouseMove = e => {
                const newPosition = {
                    x: e.clientX - startX,
                    y: e.clientY - startY
                };
                handleSnap(newPosition, {...size});
            };

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        },
        [position.x, position.y, size, handleSnap]
    );

    const handleResizeMouseDown = useCallback(
        e => {
            e.preventDefault();
            let startX = e.clientX;
            let startY = e.clientY;
            let startWidth = size.width;
            let startHeight = size.height;

            const handleMouseMove = e => {
                // If snapped left, it should only resize width, if snapped top, only height, but if snapped right or bottom, both the size but also the position should be updated, the position should be old position + (new size - old size)
                const newSize = {...size};
                const newPosition = {...position};
                if (snappedTo === 'left') {
                    newSize.width = startWidth + e.clientX - startX;
                } else if (snappedTo === 'top') {
                    newSize.height = startHeight + e.clientY - startY;
                } else if (snappedTo === 'right') {
                    newSize.width = startWidth + e.clientX - startX;
                    newPosition.x = position.x - (newSize.width - startWidth);
                } else if (snappedTo === 'bottom') {
                    newSize.height = startHeight + e.clientY - startY;
                    newPosition.y = position.y - (newSize.height - startHeight);
                }
                setSize(newSize);
                setPosition(newPosition);
            };

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        },
        [size, position, handleSnap]
    );

    useEffect(() => {
        // If not active, update url to remove hash if its #debug
        if (!active && window.location.hash === '#debug') {
            window.location.hash = '';
        }
    }, [active]);

    useEffect(() => {
        // loggerRef should always have its top and left values move towards the position state
        if (loggerRef.current) {
            let req;
            const loop = () => {
                if (!loggerRef.current) {
                    cancelAnimationFrame(req);
                    return;
                }

                loggerRef.current.topCurrent += (positionRef.y - loggerRef.current.offsetTop) / 10;
                loggerRef.current.leftCurrent += (positionRef.x - loggerRef.current.offsetLeft) / 10;
                loggerRef.current.widthCurrent += (sizeRef.width - loggerRef.current.offsetWidth) / 10;
                loggerRef.current.heightCurrent += (sizeRef.height - loggerRef.current.offsetHeight) / 10;

                loggerRef.current.style.top = loggerRef.current.topCurrent + 'px';
                loggerRef.current.style.left = loggerRef.current.leftCurrent + 'px';
                loggerRef.current.style.width = loggerRef.current.widthCurrent + 'px';
                loggerRef.current.style.height = loggerRef.current.heightCurrent + 'px';

                req = requestAnimationFrame(loop);
            };

            loop();

            return () => cancelAnimationFrame(req);
        }
    }, [positionRef, sizeRef, loggerRef]);

    if (!active) return null;

    return (
        <LoggerContainer
            key="logger"
            ref={loggerRef}
            /*bg={bgColor}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="md"
            boxShadow="lg"
            zIndex={9999}
            overflow="hidden"
            opacity={snappedTo !== 'none' ? 1 : 0.3}
            _hover={{opacity: 1}}*/

            bg="#1a202c"
            borderColor={borderColor}
            style={{
                opacity: snappedTo !== 'none' ? 1 : 0.3,
                top: position.y,
                left: position.x,
                width: size.width,
                height: size.height
            }}
        >
            <VStack height="100%" spacing={0}>
                <HStack
                    width="100%"
                    justify="space-between"
                    bg={headerBgColor}
                    p={2}
                    cursor="move"
                    onMouseDown={handleMouseDown}
                >
                    <Text fontWeight="bold">Debug Logger</Text>
                    <Button size="sm" colorScheme="red" onClick={toggleActive}>
                        <FiX />
                    </Button>
                </HStack>
                <Box
                    flex={1}
                    width="100%"
                    overflowY="auto"
                    display={collapsed ? 'none' : 'block'}
                    _css={{'&::-webkit-scrollbar': {width: 8}}}
                >
                    <List spacing={1} p={2}>
                        {log.map(({msg, name, id}) => (
                            <ListItem key={id} fontSize="sm">
                                <Box>
                                    <Text fontWeight="bold" mb={2}>
                                        {name}
                                    </Text>
                                    {React.isValidElement(msg) ? msg : JSON.stringify(msg)}
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                <HStack width="100%" justify="space-between" p={2} bg={headerBgColor}>
                    <Button size="sm" colorScheme="blue" onClick={clearLog} leftIcon={<FiTrash2 />}>
                        Clear
                    </Button>
                    <ResizePanelBorder
                        size="sm"
                        colorScheme="green"
                        onMouseDown={handleResizeMouseDown}
                        cursor="se-resize"
                        snap={snappedTo}
                    />
                </HStack>
            </VStack>
        </LoggerContainer>
    );
};

const ResizePanelBorder = styled.div`
    position: absolute;
    ${({snap}) => {
        if (snap === 'left') {
            return css`
                top: 0;
                right: 0;
                bottom: 0;
                width: 5px;
                cursor: ew-resize;
            `;
        } else if (snap === 'top') {
            return css`
                left: 0;
                right: 0;
                bottom: 0;
                height: 5px;
                cursor: ns-resize;
            `;
        } else if (snap === 'right') {
            return css`
                top: 0;
                left: 0;
                bottom: 0;
                width: 5px;
                cursor: ew-resize;
            `;
        } else if (snap === 'bottom') {
            return css`
                top: 0;
                left: 0;
                right: 0;
                height: 5px;
                cursor: ns-resize;
            `;
        }

        return css`
            bottom: 0;
            right: 0;
            cursor: se-resize;
            width: 10px;
            height: 10px;
        `;
    }}
    background-color: #f7fafc;
    opacity: 0.1;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
`;

const Highlight = ({children}) => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
            style={{border: '1px solid red'}}
        >
            {children}
        </motion.div>
    );
};

const useLogger = (name, renderFn, deps) => {
    const id = useRef(Math.random().toString(36).slice(2));

    useEffect(() => {
        const content = renderFn();
        window.logger(
            id,
            name,

            <pre>{content}</pre>
        );
    }, [name, renderFn, deps, id]);
};

export {useLogger, Highlight};
export default DebugLogger;
