import React, {useState, useEffect} from 'react';
import VideoRenderer from './VideoRenderer';
import './App.css';
import {createGlobalStyle} from 'styled-components';
import {ChakraProvider, extendTheme, ColorModeProvider} from '@chakra-ui/react';
import DebugLogger from './DebugLogger.js';
import Layout from './MainLayout.js';

const customDarkColor = localStorage.getItem('darkColor') || '#1a202c'; //'#141125';

const GlobalStyle = createGlobalStyle`
body{
    height: auto;
}
  body > *:not(.chakra-portal) {
    // Your global styles here
  }

  .chakra-modal__content-container {
    padding-top: var(--offset-top);
    padding-bottom: var(--offset-bottom);
    padding-left: var(--offset-left);
    padding-right: var(--offset-right);
  }
`;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error('Error caught by ErrorBoundary: ', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{padding: '20px', color: 'red'}}>
                    <h2>Something went wrong.</h2>
                    <p>{this.state.error?.message}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

const createTheme = darkColor => {
    return extendTheme({
        styles: {
            global: props => ({
                body: {
                    bg: props.colorMode === 'dark' ? darkColor : 'white'
                }
            })
        },
        colors: {
            // Customize your dark mode colors
            dark: {
                50: '#f7fafc',
                100: '#edf2f7',
                200: '#e2e8f0',
                300: '#cbd5e0',
                400: '#a0aec0',
                500: darkColor,
                600: '#2c5282',
                700: '#2a4365',
                800: '#1A365D',
                900: '#153e75'
            }
        }
    });
};

function App() {
    const [darkColor, setDarkColor] = useState(customDarkColor);
    const [theme, setTheme] = useState(() => createTheme(darkColor));

    // Update dark color if body gets --dark-color css var, or if that css var changes
    useEffect(() => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'style') {
                    const darkColor = getComputedStyle(document.body).getPropertyValue('--dark-color');
                    if (darkColor !== customDarkColor) {
                        setDarkColor(darkColor);

                        localStorage.setItem('darkColor', darkColor);
                    }
                }
            });
        });

        observer.observe(document.body, {
            attributes: true,
            attributeFilter: ['style']
        });

        // Set initial dark color var to body
        document.body.style.setProperty('--dark-color', customDarkColor);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        setTheme(createTheme(darkColor));
    }, [darkColor]);

    return (
        <ChakraProvider theme={theme}>
            <ColorModeProvider options={{initialColorMode: 'dark'}}>
                <GlobalStyle />
                <ErrorBoundary>
                    {' '}
                    {/* Wrap your components with ErrorBoundary */}
                    <Layout>
                        <div style={{minHeight: '100vh'}}>
                            <VideoRenderer />
                        </div>
                        <DebugLogger />
                    </Layout>
                </ErrorBoundary>
            </ColorModeProvider>
        </ChakraProvider>
    );
}

export default App;
