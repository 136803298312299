// Pubsub for real-time logging
import React, { useEffect, useState } from 'react';

// Chakraui
import { Progress } from '@chakra-ui/react';

const realTimeLog = (message, type = 'log', progress, color = colorMap[type]) => {
    // Progress is an optional number 0 - 100 to indicate progress
    // Send message to window
    window.dispatchEvent(new MessageEvent('message', {
        data: {
            type: 'realTimeLog',
            logType: type,
            message,
            progress,
            color
        }
    }));
};

const colorMap = {
    log: 'green',
    error: 'red',
    warn: 'yellow',
    info: 'blue'
};

const RealTimeLogger = React.memo(({
    type,
    children
}) => {
    // Subscribes to the real-time log
    const [status, setStatus] = useState('');
    const [progress, setProgress] = useState(0);
    const [color, setColor] = useState('green');

    useEffect(() => {
        const handler = event => {
            if (event.data.type === 'realTimeLog' && event.data.logType === type) {
                setStatus(event.data.message);
                if (typeof event.data.progress === 'number') {
                    setProgress(event.data.progress);
                } else {
                    setProgress(0);
                }

                if (event.data.color) {
                    setColor(event.data.color);
                }
            }
        };

        window.addEventListener('message', handler);

        return () => {
            window.removeEventListener('message', handler);
        };
    }, [type]);

    return (
        <div>
            <div>
                {children} <br />
                {status}
            </div>
            {progress > 0 && (
                <Progress
                    colorScheme={color}
                    value={progress}
                    sx={{
                        "& > div": {
                            transitionProperty: "width",
                        },
                    }}
                />
            )}
        </div>
    );
});

export {
    realTimeLog,
    RealTimeLogger
};