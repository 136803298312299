import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Input } from '@chakra-ui/react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

/*
Usage:

<MultitypeInput
    file={audioFile}
    url={audioUrl}
    onFile={(e) => {
        setAudioFile(e.target.files[0]);
        setAudioUrl(''); // Reset audio url
    }}
    onUrl={(e) => {
        setAudioUrl(e.target.value);
        setAudioFile(null); // Reset audio file
    }}
>
    <legend>Some label</legend>
</MultitypeInput>

It should also be very nicely styled, you have acccess to react-icons as well
*/

const MultiTypeInput = ({ fileName, url, onFile, onUrl, children }) => {
    const [activeTab, setActiveTab] = useState('file');

    return (
        <div>
            <Tabs>
                <TabList>
                    <Tab onClick={() => setActiveTab('file')} active={activeTab === 'file'}>File</Tab>
                    <Tab onClick={() => setActiveTab('url')} active={activeTab === 'url'}>URL</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel px={0}>
                        {fileName ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {fileName}
                            </motion.div>
                        ) : (
                            <Input type="file" onChange={onFile} variant="filled" />
                        )}
                    </TabPanel>
                    <TabPanel px={0}>
                        <Input type="text" onChange={onUrl} value={url} variant="filled" />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
}

export default MultiTypeInput;