import {getFFMPEG, fetchFile} from './getFFMPEG.js';
import moment from 'moment';

// Get ffmpeg instance preloaded
getFFMPEG();

let fileCache = (window.fileCache = {});
window.fileCache = fileCache;

const convertTo = async (inputFile, format, logger) => {
    /*if (fileCache[audioFile.name]) {
        return fileCache[audioFile.name];
    }*/

    if (!logger) {
        logger = console.log;
    }

    let duration = Infinity;
    const ogLogger = logger;
    logger = message => {
        const progress = message.match(/time=([^ ]+) bitrate=/);
        if (message.indexOf('Aborted') !== -1) {
            return;
        }
        if (progress) {
            const time = moment.duration(progress[1]).asSeconds();
            const percentage = (time / duration) * 100;
            // console.log('Progress:', percentage);
            ogLogger([message], percentage);
        } else {
            ogLogger([message]);
        }
    };

    logger('Getting duration of input file');

    duration = await new Promise(resolve => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(inputFile);
        audio.onloadedmetadata = () => {
            resolve(audio.duration);
        };
    });

    fileCache[inputFile.name] = new Promise(async (resolve, reject) => {
        logger('Preparing to convert file to ' + format + '...');
        const ffmpeg = await getFFMPEG(logger);

        // audioFile is a File object
        const file = await fetchFile(inputFile);

        await ffmpeg.writeFile('ogfile', file);

        logger('Converting file to ' + format + '...');

        await ffmpeg.exec([
            '-i',
            'ogfile',
            '-preset',
            'ultrafast',
            '-b:a',
            '320k',
            // In case of video, limit to 1080p
            '-vf',
            'scale=-1:720',
            'output.' + format
        ]);

        const data = await ffmpeg.readFile('output.' + format);

        const outputFile = new File([data], inputFile.name.replace(/\.[^.]+$/, '.' + format));

        ogLogger(['File converted to ' + format + ' successfully!'], 0);
        resolve(outputFile);
    });

    return fileCache[inputFile.name];
};

export {convertTo};
