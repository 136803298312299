// useStateWithIndexedDB.js
import {useState, useEffect} from 'react';
import {openDB} from 'idb';
import {getClass} from './classRegistry.js';

/**
 * Custom hook to manage state with IndexedDB persistence, supporting class instances.
 * @param {string} key - The key to store in IndexedDB.
 * @param {*} initialValue - The initial value for the state.
 * @returns {[any, Function]} The state and the function to update it.
 */
const useStateWithIndexedDB = (key, initialValue) => {
    const [state, setState] = useState(initialValue);

    /**
     * Serializes the value, including class information if it's an instance of a registered class.
     * @param {*} value - The value to serialize.
     * @returns {*} The serialized value.
     */
    const serialize = value => {
        if (value && typeof value === 'object' && getClass(value.constructor.name)) {
            const constructor = value.constructor;
            if (constructor && constructor.name && constructor !== Object) {
                return {
                    __class: constructor.name,
                    ...value
                };
            }
        }
        return value;
    };

    /**
     * Deserializes the value, reconstructing class instances based on the stored class information.
     * @param {*} value - The value to deserialize.
     * @returns {*} The deserialized value.
     */
    const deserialize = value => {
        if (value && typeof value === 'object' && '__class' in value) {
            const className = value.__class;
            const ClassConstructor = getClass(className);
            if (ClassConstructor) {
                const {__class, ...data} = value;
                if (typeof ClassConstructor.fromJSON === 'function') {
                    return ClassConstructor.fromJSON(data);
                } else {
                    const instance = new ClassConstructor();
                    Object.assign(instance, data);
                    return instance;
                }
            }
        }
        return value;
    };

    useEffect(() => {
        if (state !== initialValue) {
            return;
        }
        openDB('state', 1, {
            upgrade(db) {
                db.createObjectStore('state');
            }
        }).then(async db => {
            try {
                const tx = db.transaction('state', 'readwrite');
                const store = tx.objectStore('state');
                const storedValue = await store.get(key);
                if (storedValue !== undefined) {
                    const deserialized = deserialize(storedValue);
                    setState(deserialized);
                } else {
                    const serialized = serialize(initialValue);
                    await store.put(serialized, key);
                }
            } catch (error) {
                console.error('Error loading state from IndexedDB:', error);
            }
        });
    }, [key, initialValue]);

    /**
     * Updates the state and persists it to IndexedDB.
     * @param {*} value - The new value to set.
     */
    const updateState = value => {
        if (value === state) {
            return;
        }

        try {
            openDB('state', 1).then(async db => {
                const tx = db.transaction('state', 'readwrite');
                const store = tx.objectStore('state');
                const serialized = serialize(value);
                await store.put(serialized, key);
                const deserialized = deserialize(serialized);
                setState(deserialized);
            });
        } catch (e) {
            console.error('Error saving state to IndexedDB:', e);
            setState(value);
        }
    };

    return [state, updateState];
};

const clearStorage = async () => {
    const db = await openDB('state', 1);
    await db.clear('state');
};

export {useStateWithIndexedDB, clearStorage};
export default useStateWithIndexedDB;
