import React, {useState, useEffect} from 'react';
import {Button, Box, Text, Progress, VStack, useToast, ButtonGroup, IconButton} from '@chakra-ui/react';

// Dropbox icon
import {FaDropbox} from 'react-icons/fa';

const DROPBOX_APP_KEY = process.env.REACT_APP_DROPBOX_APP_KEY;
const REDIRECT_URI = window.location.href;

const useLocalStorage = (key, initialValue = null) => {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue ? storedValue : initialValue;
    });

    const setStoredValue = newValue => {
        setValue(newValue);
        localStorage.setItem(key, newValue);
    };

    return [value, setStoredValue];
};

const DropboxSyncComponent = ({folderHandle}) => {
    const [show, setShow] = useLocalStorage('dropboxSyncShow', false);
    const [syncStatus, setSyncStatus] = useState('idle');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useLocalStorage('dropboxAccessToken');
    const toast = useToast();

    useEffect(() => {
        // Check if we have a token in the URL hash
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const token = params.get('access_token');
        if (token) {
            setAccessToken(token);
            // Clear the hash
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    const handleStartSync = async () => {
        if (!accessToken) {
            // Start the auth flow
            const authUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${DROPBOX_APP_KEY}&response_type=token&redirect_uri=${REDIRECT_URI}`;
            window.location.href = authUrl;
        } else {
            try {
                setSyncStatus('syncing');
                setProgress(0);
                await syncFolder(folderHandle);
                setSyncStatus('completed');
                toast({
                    title: 'Sync Completed',
                    description: 'Your folder has been successfully synced to Dropbox.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
            } catch (err) {
                console.error('Sync failed:', err);
                setError(err.message);
                setSyncStatus('error');
                toast({
                    title: 'Sync Failed',
                    description: err.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        }
    };

    const syncFolder = async (handle, path = '') => {
        if (handle.kind === 'file') {
            await syncFile(handle, path);
        } else if (handle.kind === 'directory') {
            await createFolder(`${path ? '/' + path : ''}/${handle.name}`);
            for await (const entry of handle.values()) {
                await syncFolder(entry, `${path}/${handle.name}`);
            }
        }
        updateProgress();
    };

    const syncFile = async (handle, path) => {
        const file = await handle.getFile();
        const contents = await file.arrayBuffer();
        await uploadFile(`${path ? path : ''}/${handle.name}`, contents);
    };

    const createFolder = async path => {
        path = path.replace(/\/\//g, '/');
        const response = await fetch('https://api.dropboxapi.com/2/files/create_folder_v2', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({path})
        });
        if (!response.ok && response.status !== 409) {
            throw new Error('Failed to create folder');
        }
    };

    const uploadFile = async (path, contents) => {
        path = path.replace(/\/\//g, '/');
        if (path.indexOf('.DS_Store') !== -1) {
            return;
        }
        // Sanitize path
        path = path.replace(/[^a-zA-Z0-9_\-/.]/g, '');

        const response = await fetch('https://content.dropboxapi.com/2/files/upload', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Dropbox-API-Arg': JSON.stringify({
                    path,
                    mode: 'overwrite'
                }),
                'Content-Type': 'application/octet-stream'
            },
            body: contents
        });
        if (!response.ok) {
            throw new Error('Failed to upload file');
        }
    };

    const updateProgress = () => {
        setProgress(prev => {
            const newProgress = prev + 1;
            return newProgress > 100 ? 100 : newProgress;
        });
    };

    const renderContent = () => {
        switch (syncStatus) {
            case 'idle':
                return (
                    <>
                        <Button colorScheme={accessToken ? 'green' : 'blue'} onClick={handleStartSync}>
                            {accessToken ? 'Start Syncing to Dropbox' : 'Authenticate with Dropbox'}
                        </Button>
                        {accessToken && (
                            <Button colorScheme="gray" onClick={() => setAccessToken(null)}>
                                Logout
                            </Button>
                        )}
                    </>
                );
            case 'syncing':
                return (
                    <VStack spacing={4} align="stretch">
                        <Text>Syncing to Dropbox...</Text>
                        <Progress value={progress} max={100} />
                    </VStack>
                );
            case 'completed':
                return <Text color="green.500">Sync completed successfully!</Text>;
            case 'error':
                return (
                    <VStack spacing={4} align="stretch">
                        <Text color="red.500">Error: {error}</Text>
                        <Button colorScheme="blue" onClick={handleStartSync}>
                            Retry Sync
                        </Button>
                    </VStack>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <IconButton
                mx={2}
                icon={<FaDropbox />}
                aria-label="Dropbox Sync"
                onClick={() => setShow(!show)}
                colorScheme="blue"
                //  Faded
                colorScheme={show ? 'blue' : 'gray'}
            />
            {show && renderContent()}
        </>
    );
};

export default DropboxSyncComponent;
