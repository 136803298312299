import {getFFMPEG, fetchFile} from './getFFMPEG.js';
import {MP4Demuxer} from './workers/demuxer_mp4.js';

let frameGenMap = new Map();

const VideoDecoder = window.VideoDecoder;

const createVideoFrameGenerator = async (videoSrc, width, height, setStatus, stopState) => {
    if (frameGenMap.has(videoSrc)) {
        return frameGenMap.get(videoSrc);
    }

    let callback = null;
    let frameCache = [];

    const getVideoFps = async () => {
        setStatus('Getting video FPS...');
        return new Promise(async resolve => {
            const ffmpeg = await getFFMPEG(text => {
                const match = text.match(/, (\d+(?:\.\d+)?) fps,/);
                if (match) {
                    resolve(parseFloat(match[1]));
                }
            });

            await ffmpeg.writeFile('video.mp4', await fetchFile(videoSrc));

            await ffmpeg.exec(['-i', 'video.mp4']);
        });
    };

    let videoFps = await getVideoFps();

    const framePubSub = new BroadcastChannel('framePubSub');

    const prerender = async () => {
        return new Promise(async resolve => {
            const video = document.createElement('video');
            video.src = videoSrc;
            video.controls = true;

            await new Promise(resolve => {
                video.onloadedmetadata = () => {
                    resolve();
                };
            });

            const totalFrames = Math.round(video.duration * videoFps);

            let frameCount = 0;
            let startTime = null;

            let lastRenderTime = 0;
            const renderFrame = async frame => {
                if (stopState.stop) {
                    cleanUp();
                    resolve();
                    return;
                }

                frameCache.push(frame);
                framePubSub.postMessage({type: 'frame', frame: frameCache.length - 1});

                if (startTime == null) {
                    startTime = performance.now();
                } else {
                    frameCount++;
                    const currentTime = frameCount / videoFps;
                    const currentFps = frameCount / ((performance.now() - startTime) / 1000);
                    if (performance.now() - lastRenderTime > 1000) {
                        await new Promise(resolve => requestAnimationFrame(resolve));
                        setStatus(
                            'Frames processed:',
                            frameCount,
                            'of',
                            totalFrames,
                            ' at ',
                            width,
                            'x',
                            height,
                            ' ',
                            currentFps.toFixed(2),
                            'fps (Video FPS:',
                            videoFps,
                            ')'
                        );
                        lastRenderTime = performance.now();
                    }
                }

                if (frameCache.length > totalFrames - 50) {
                    resolve();
                }
            };

            const decoder = new VideoDecoder({
                output(frame) {
                    if (stopState.stop) {
                        cleanUp();
                        resolve();
                        return;
                    }
                    frame.width = width;
                    frame.height = height;
                    renderFrame(frame);
                },
                error(e) {
                    setStatus('decode error', e);
                },
                complete() {
                    setStatus('decode', 'complete');
                }
            });

            const demuxer = new MP4Demuxer(videoSrc, {
                onConfig(config) {
                    if (stopState.stop) {
                        cleanUp();
                        resolve();
                        return;
                    }
                    setStatus('decode', `${config.codec} @ ${config.codedWidth}x${config.codedHeight}`);
                    decoder.configure(config);
                },
                onChunk(chunk) {
                    if (stopState.stop) {
                        cleanUp();
                        resolve();
                        return;
                    }
                    decoder.decode(chunk);
                },
                onDone() {
                    decoder.flush();
                },
                onError(e) {
                    setStatus('demuxer error', e);
                    decoder.flush();
                },
                setStatus
            });
        });
    };

    const onFrame = cb => {
        callback = cb;
    };

    const update = currentTime => {
        if (stopState.stop) {
            cleanUp();
            return new Image(1, 1);
        }

        const frameNumber = Math.floor(currentTime * videoFps) % frameCache.length;
        if (callback && !isNaN(frameNumber) && frameCache[frameNumber]) {
            callback(frameCache[frameNumber]);
            return frameCache[frameNumber];
        } else if (isNaN(frameNumber)) {
            return new Image(1, 1);
        } else {
            console.log('Frame not found: ' + frameNumber);
            return new Promise(resolve => {
                const handler = message => {
                    if (message.data.type === 'frame' && message.data.frame === frameNumber) {
                        callback && callback(frameCache[frameNumber]);
                        resolve(frameCache[frameNumber]);
                        framePubSub.removeEventListener('message', handler);
                    }
                };
                framePubSub.addEventListener('message', handler);
            });
        }
    };

    const cleanUp = () => {
        frameGenMap.delete(videoSrc);
        framePubSub.close();
        // Additional cleanup if needed
    };

    const remove = () => {
        cleanUp();
    };

    let videoFrameGen = {
        onFrame,
        update,
        remove,
        // Can be updated later
        setArguments: async opts => {
            if (opts.width) {
                width = opts.width;
            }
            if (opts.height) {
                height = opts.height;
            }
            if (opts.stopState) {
                stopState = opts.stopState;
            }
            if (opts.setStatus) {
                setStatus = opts.setStatus;
            }
            if (opts.videoSrc) {
                videoSrc = opts.videoSrc;
            }
            if (opts.videoFps) {
                videoFps = opts.videoFps;
            }
        }
    };

    frameGenMap.set(videoSrc, videoFrameGen);

    prerender();

    return videoFrameGen;
};

export default createVideoFrameGenerator;
