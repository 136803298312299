import React, {useState, useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {
    ChakraProvider,
    Box,
    VStack,
    Button,
    Input,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react';
import {motion, AnimatePresence} from 'framer-motion';
import styled from '@emotion/styled';
import {registerClass} from './classRegistry.js';

const DB_NAME = 'FileSystemDB';
const DB_VERSION = 2.2;
const STORE_NAME = 'files';

function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);
        request.onupgradeneeded = event => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                const store = db.createObjectStore(STORE_NAME, {keyPath: 'path'});
                store.createIndex('parent', 'parent', {unique: false});
            }
        };
        request.onsuccess = event => {
            resolve(event.target.result);
        };
        request.onerror = event => {
            reject(event.target.error);
        };
    });
}

async function getDirectoryContents(path) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const index = store.index('parent');
        const request = index.getAll(path);
        request.onsuccess = event => {
            resolve(event.target.result);
        };
        request.onerror = event => {
            reject(event.target.error);
        };
    });
}

async function getFile(path) {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.get(path);
        request.onsuccess = event => {
            resolve(event.target.result);
        };
        request.onerror = event => {
            reject(event.target.error);
        };
    });
}

async function createEntry(path, type, content = '') {
    const db = await openDB();
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(STORE_NAME, 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.put({path, type, parent: path.substring(0, path.lastIndexOf('/')), content});
        request.onsuccess = event => {
            resolve(event.target.result);
        };
        request.onerror = event => {
            reject(event.target.error);
        };
    });
}

class FileSystemFileHandlePolyfill {
    constructor(path) {
        if (!path) {
            return null;
        }
        this.path = path;
        this.kind = 'file';
        this.name = path.substring(path.lastIndexOf('/') + 1);
    }

    async getFile() {
        const entry = await getFile(this.path);
        if (!entry) {
            return null;
        }
        return new File([entry.content], this.path.substring(this.path.lastIndexOf('/') + 1));
    }

    async createWritable() {
        const path = this.path;
        return {
            async write(data) {
                await createEntry(path, 'file', data);
            },
            async close() {}
        };
    }

    async remove() {
        const db = await openDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.delete(this.path);
            request.onsuccess = event => {
                resolve(event.target.result);
            };
            request.onerror = event => {
                reject(event.target.error);
            };
        });
    }

    async getFileHandle() {
        return this;
    }
}

class FileSystemDirectoryHandlePolyfill {
    constructor(path) {
        if (!path) {
            return null;
        }
        this.path = path;
        this.kind = 'directory';
        this.name = path.substring(path.lastIndexOf('/') + 1);
    }

    async *entries() {
        const contents = await getDirectoryContents(this.path);
        for (const entry of contents) {
            yield [
                entry.path.substring(entry.path.lastIndexOf('/') + 1),
                entry.type === 'file'
                    ? new FileSystemFileHandlePolyfill(entry.path)
                    : new FileSystemDirectoryHandlePolyfill(entry.path)
            ];
        }
    }

    async *values() {
        for await (const [name, handle] of this.entries()) {
            yield handle;
        }
    }

    async getDirectoryHandle(name, options = {}) {
        const path = `${this.path}/${name}`;
        if (options.create) {
            await createEntry(path, 'directory');
        }
        return new FileSystemDirectoryHandlePolyfill(path);
    }

    async getFileHandle(name, options = {}) {
        const path = `${this.path}/${name}`;
        if (options.create) {
            await createEntry(path, 'file');
        }
        return new FileSystemFileHandlePolyfill(path);
    }

    async removeEntry(name, options = {}) {
        const path = `${this.path}/${name}`;
        const db = await openDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.delete(path);
            request.onsuccess = event => {
                resolve(event.target.result);
            };
            request.onerror = event => {
                reject(event.target.error);
            };
        });
    }
}

const clearStorage = () => {
    indexedDB.deleteDatabase(DB_NAME);
};

const StyledButton = styled(Button)`
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
`;

const ProjectItem = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: #f0f0f0;
    color: #333;
    margin-bottom: 10px;
`;

const ProjectPicker = ({onSelect}) => {
    const [projects, setProjects] = useState([]);
    const [newProjectName, setNewProjectName] = useState('');

    useEffect(() => {
        // Load existing projects from IndexedDB
        const loadProjects = async () => {
            const db = await openDB();
            const transaction = db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const index = store.index('parent');
            const request = index.openCursor(IDBKeyRange.only('projects'));
            request.onsuccess = event => {
                const cursor = event.target.result;
                if (cursor) {
                    setProjects(projects => [...projects, cursor.value]);
                    cursor.continue();
                }
            };
        };
        loadProjects();
    }, []);

    const handleCreateProject = async () => {
        if (newProjectName.trim()) {
            await createEntry(`projects/${newProjectName}`, 'directory');
            setProjects([...projects, {path: `projects/${newProjectName}`, type: 'directory'}]);
            setNewProjectName('');
        }
    };

    const handleSelectProject = path => {
        onSelect(path);
    };

    const handleRemoveProject = async path => {
        await new FileSystemDirectoryHandlePolyfill(path).removeEntry(path.split('/').pop());

        // Remove all files and directories inside the project
        const db = await openDB();
        const transaction = db.transaction(STORE_NAME, 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
        const index = store.index('parent');
        const request = index.openCursor(IDBKeyRange.only(path));
        request.onsuccess = event => {
            const cursor = event.target.result;
            if (cursor) {
                store.delete(cursor.value.path);
                cursor.continue();
            }
        };

        // Remove the project itself
        store.delete(path);

        setProjects(projects.filter(project => project.path !== path));
    };

    return (
        <ChakraProvider>
            <Modal isOpen={true} onClose={() => {}}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Project Picker</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Box width="100%">
                                <Input
                                    placeholder="New project name"
                                    value={newProjectName}
                                    onChange={e => setNewProjectName(e.target.value)}
                                />
                                <StyledButton mt={2} onClick={handleCreateProject}>
                                    Create New Project
                                </StyledButton>
                            </Box>
                            <Text fontWeight="bold">Existing Projects:</Text>
                            <AnimatePresence>
                                {projects.map(project => (
                                    <ProjectItem
                                        key={project.path}
                                        initial={{opacity: 0, y: -10}}
                                        animate={{opacity: 1, y: 0}}
                                        exit={{opacity: 0, y: -10}}
                                    >
                                        <Text>{project.path}</Text>
                                        <Box>
                                            <StyledButton
                                                size="sm"
                                                mr={2}
                                                onClick={() => handleSelectProject(project.path)}
                                            >
                                                Select
                                            </StyledButton>
                                            <StyledButton
                                                size="sm"
                                                colorScheme="red"
                                                onClick={() => handleRemoveProject(project.path)}
                                            >
                                                Remove
                                            </StyledButton>
                                        </Box>
                                    </ProjectItem>
                                ))}
                            </AnimatePresence>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    );
};

export const selectDirectory = () => {
    return new Promise(resolve => {
        const root = document.createElement('div');
        document.body.appendChild(root);

        const reactRoot = createRoot(root);

        const cleanup = () => {
            reactRoot.unmount();
            document.body.removeChild(root);
        };

        reactRoot.render(
            <ProjectPicker
                onSelect={path => {
                    cleanup();
                    resolve(path);
                }}
            />
        );
    });
};

if (!('showDirectoryPicker' in window) || window.location.hash === '#test') {
    registerClass('FileSystemFileHandlePolyfill', FileSystemFileHandlePolyfill);
    registerClass('FileSystemDirectoryHandlePolyfill', FileSystemDirectoryHandlePolyfill);
}

export {
    FileSystemFileHandlePolyfill as FileSystemFileHandle,
    FileSystemDirectoryHandlePolyfill as FileSystemDirectoryHandle,
    clearStorage
};

export async function showDirectoryPicker() {
    return new FileSystemDirectoryHandlePolyfill(await selectDirectory());
}
