import React, {useState, useRef, useEffect} from 'react';
import {motion, AnimatePresence, useDragControls, useAnimationControls} from 'framer-motion';
import styled from 'styled-components';
import {useGesture} from 'react-use-gesture';
import {FaVideo, FaMusic, FaFont, FaPlus} from 'react-icons/fa';

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

const TimelineContainer = styled.div`
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
    background: #f0f0f0;
    height: 200px;
    touch-action: none;
    cursor: grab;
    user-select: none;
    width: 100%;

    @media (min-width: 720px) {
        max-width: 50vw;
    }
`;

const ItemsContainer = styled.div`
    position: relative;
    height: 100%;
`;

const ItemComponent = ({zoomLevel, duration, item, onDrag, onDragEnd, onClick, ...props}) => {
    const dragControls = useDragControls();
    const animationControls = useAnimationControls();

    useEffect(() => {
        animationControls.set({
            left: item.startTime * zoomLevel,
            width: item.duration * zoomLevel,
            top: item.lane * 20 + 30,
            x: 0,
            y: 0
        });
    }, [animationControls, item.startTime, item.duration, item.lane, zoomLevel]);

    return (
        <motion.div
            key={item.id}
            animate={animationControls}
            dragControls={dragControls}
            drag="x"
            //dragConstraints={{left: 0, right: duration * zoomLevel - item.duration * zoomLevel}}
            dragTransition={{bounceDamping: 0}}
            dragMomentum={false}
            dragElastic={0}
            onDrag={onDrag}
            onDragEnd={(event, info) => {
                onDragEnd(event, info);
                animationControls.set({
                    left: item.startTime,
                    width: item.duration,
                    top: item.lane * 20 + 30,
                    x: 0,
                    y: 0
                });
            }}
            onClick={onClick}
            {...props}
        >
            {item.icon}
        </motion.div>
    );
};

const Item = styled(ItemComponent)`
    position: absolute;
    height: 15px;
    background-color: ${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
    user-select: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    touch-action: none;

    &:active {
        cursor: grabbing;
    }
`;

const GridLine = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
`;

const TimeLabel = styled.div`
    position: absolute;
    top: 5px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
    transform: translateX(-50%);
`;

const AddItemButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: #45a049;
    }
`;

const Timeline = ({items, onItemClick, onItemsUpdate, zoomLevel, onZoomChange, gridInterval, duration}) => {
    const [itemStates, setItemStates] = useState(() =>
        items.reduce(
            (acc, item) => ({
                ...acc,
                [item.id]: {
                    startTime: item.startTime,
                    endTime: item.startTime + item.duration,
                    lane: item.lane || 0
                }
            }),
            {}
        )
    );

    const containerRef = useRef(null);

    useEffect(() => {
        setItemStates(
            items.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: {
                        startTime: item.startTime,
                        endTime: item.startTime + item.duration,
                        lane: item.lane || 0
                    }
                }),
                {}
            )
        );
    }, [items]);

    const handleItemDragStart = (id, _, info) => {
        setItemStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                dragStartTime: prev[id].startTime
            }
        }));
    };

    const handleItemDrag = (id, _, info) => {
        const deltaX = info.offset.x;
        const newStartTime = Math.max(0, itemStates[id].dragStartTime + deltaX / 1);
        const newEndTime = newStartTime + itemStates[id].duration;

        setItemStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                startTime: newStartTime,
                endTime: newEndTime
            }
        }));
    };

    const handleItemDragEnd = (id, _, info) => {
        setItemStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                dragStartTime: undefined
            }
        }));

        const updatedItems = items.map(item =>
            item.id === id
                ? {
                      ...item,
                      startTime: itemStates[id].startTime
                  }
                : item
        );
        onItemsUpdate(updatedItems);
    };

    const handleZoom = event => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
            // Max zoom is so that the full duration fits in the container
            const maxZoom = containerRef.current.clientWidth / duration;
            const newZoomLevel = Math.max(maxZoom, Math.min(5, zoomLevel + (event.deltaY > 0 ? -0.025 : 0.025)));
            onZoomChange(newZoomLevel);
        }
    };

    const handleAddItem = () => {
        const newItem = {
            id: `item-${Date.now()}`,
            type: 'video',
            startTime: 0,
            duration: 5000,
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            icon: <FaVideo />,
            lane: 0
        };
        onItemsUpdate([...items, newItem]);
    };

    useEffect(() => {
        const handleKeyDown = event => {
            if (event.ctrlKey || event.metaKey) {
                if (event.key === '=' || event.key === '+') {
                    onZoomChange(Math.min(15, zoomLevel + 0.1));
                } else if (event.key === '-') {
                    onZoomChange(Math.max(0.01, zoomLevel - 0.1));
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [zoomLevel, onZoomChange]);

    const getTimeLabel = time => {
        const seconds = Math.floor(time / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        return `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        // Add non passive event listener to handle wheel event
        containerRef.current.addEventListener('wheel', handleZoom, {passive: false});
        return () => containerRef.current && containerRef.current.removeEventListener('wheel', handleZoom);
    }, [zoomLevel]);

    return (
        <TimelineContainer ref={containerRef}>
            <ItemsContainer style={{width: `${duration * zoomLevel}px`}}>
                {/* The count must scale with the duration and zoom level */}
                {Array.from({length: Math.ceil(duration / gridInterval) + 1}, (_, i) => (
                    <React.Fragment key={i}>
                        <GridLine style={{left: `${i * gridInterval * zoomLevel}px`}} />
                        <TimeLabel style={{left: `${i * gridInterval * zoomLevel}px`}}>
                            {getTimeLabel(i * gridInterval)}
                        </TimeLabel>
                    </React.Fragment>
                ))}
                <AnimatePresence>
                    {items.map(item => (
                        <Item
                            key={item.id}
                            color={item.color}
                            zoomLevel={zoomLevel}
                            duration={duration}
                            item={item}
                            onDrag={(_, info) => handleItemDrag(item.id, _, info)}
                            onDragEnd={(_, info) => handleItemDragEnd(item.id, _, info)}
                            onClick={() => onItemClick(item)}
                        ></Item>
                    ))}
                </AnimatePresence>
            </ItemsContainer>
            <AddItemButton onClick={handleAddItem}>
                <FaPlus />
            </AddItemButton>
        </TimelineContainer>
    );
};

const TimelineDemo = () => {
    const [items, setItems] = useState([
        {
            id: 'item-1',
            type: 'video',
            startTime: 0,
            duration: 5000,
            color: '#f00',
            icon: <FaVideo />,
            lane: 0
        },
        {
            id: 'item-2',
            type: 'music',
            startTime: 2000,
            duration: 3000,
            color: '#0f0',
            icon: <FaMusic />,
            lane: 1
        },
        {
            id: 'item-3',
            type: 'text',
            startTime: 4000,
            duration: 2000,
            color: '#00f',
            icon: <FaFont />,
            lane: 2
        }
    ]);

    const [zoomLevel, setZoomLevel] = useLocalStorage('zoomLevel', 1);
    // Base grid interval on zoom level
    const gridInterval = (() => {
        // The point here is to set this based on the zoom level to prevent the grid lines from being too close together
        // and to prevent the labels from being too close together
        // The grid interval should be a multiple of 1000

        return 1000;

        // If less than 0.2, every 0.1 zoom level should increase the grid interval by 1000
        const threshold = 0.1;
        return 1000 * Math.ceil(threshold / zoomLevel);
    })();
    const [duration, setDuration] = useState(10000);

    const handleItemClick = item => {
        console.log('Item clicked:', item);
    };

    const handleItemsUpdate = updatedItems => {
        setItems(updatedItems);
    };

    const handleZoomChange = newZoomLevel => {
        setZoomLevel(newZoomLevel);
    };

    return (
        <div>
            <input
                type="range"
                min="1"
                max="100"
                value={zoomLevel * 100}
                onChange={event => setZoomLevel(event.target.value / 100)}
            />
            <br />
            <span>Zoom: {zoomLevel}</span>
            <br />
            <span>{gridInterval}</span>
            <br />
            <input
                type="text"
                value={duration}
                onChange={event => {
                    setDuration(event.target.value);
                }}
            />
            <br />
            <Timeline
                items={items}
                onItemClick={handleItemClick}
                onItemsUpdate={handleItemsUpdate}
                zoomLevel={zoomLevel}
                onZoomChange={handleZoomChange}
                gridInterval={gridInterval}
                duration={duration}
            />
        </div>
    );
};

export {Timeline, TimelineDemo};
export default Timeline;
