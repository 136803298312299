import srtParser2 from 'srt-parser-2';
var parser = new srtParser2();

// const srtRender = new CreateSrtRenderer('/subtitles.srt');
// srtRender.getText(currentTime),

class CreateSrtRenderer {
    constructor(srtUrl, settings = {debug: false, updateSrt: false}) {
        this.srtUrl = srtUrl;
        this.srt = null;
        this.currentText = '';
        this.updateSrt = settings.updateSrt;

        if (settings.debug) {
            this.debug = settings.debug;
        }
    }

    async fetchSrt() {
        const response = await fetch(this.srtUrl);
        const text = await response.text();

        var srt_array = parser.fromSrt(text);

        /*
            Array of srt lines, example of one line:
            {
                "id": "3",
                "startTime": "00:00:07,900",
                "startSeconds": 7.9,
                "endTime": "00:00:11,900",
                "endSeconds": 11.9,
                "text": "Beneath the overpass, our sanctuary from the storm"
            }
        */

        this.srt = srt_array;
        if (this.debug) {
            console.log('srt', this.srt);
        }
    }

    // Updates the currently active line with the provided text and saves the file again
    setText(text) {
        if (!this.srt) {
            return;
        }

        for (let i = 0; i < this.srt.length; i++) {
            const line = this.srt[i];
            if (line.text === this.currentText) {
                line.text = text;
                break;
            }
        }

        const srt = parser.toSrt(this.srt);

        if (!this.updateSrt) {
            const blob = new Blob([srt], {type: 'text/plain'});
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'subtitles.srt';
            a.click();
        } else {
            this.updateSrt(srt);
        }
    }

    getText(currentTime) {
        if (!this.srt && !this.fetchingSrt) {
            this.fetchingSrt = this.fetchSrt().then(() => {
                this.fetchingSrt = null;
            });
            return '';
        } else if (!this.srt) {
            return '';
        }

        let text = '';
        for (let i = 0; i < this.srt.length; i++) {
            const line = this.srt[i];
            if (currentTime >= line.startSeconds && currentTime <= line.endSeconds) {
                if (this.debug) {
                    text = '[' + line.id + '] ' + line.text;
                } else {
                    text = line.text;
                }
                break;
            }
        }

        if (this.currentText !== text) {
            this.currentText = text;
            if (this.debug) {
                console.log('currentText', this.currentText);
            }
        }

        return text;
    }

    getTextOffset(currentTime, offset) {
        // For example if offset is -1, the previous line will be returned or if its -5, the previous 5 lines will be returned
        // For example if offset is 1, the next line will be returned or if its 5, the next 5 lines will be returned
        if (!this.srt && !this.fetchingSrt) {
            this.fetchingSrt = this.fetchSrt().then(() => {
                this.fetchingSrt = null;
            });
            return '';
        } else if (!this.srt) {
            return '';
        }

        let lines = [];

        for (let i = 0; i < this.srt.length; i++) {
            const line = this.srt[i];
            if (currentTime >= line.startSeconds && currentTime <= line.endSeconds) {
                lines.push(line);

                if (offset > 0) {
                    for (let j = 1; j <= offset; j++) {
                        if (this.srt[i + j]) {
                            lines.push(this.srt[i + j]);
                        }
                    }
                } else if (offset < 0) {
                    for (let j = 1; j <= Math.abs(offset); j++) {
                        if (this.srt[i - j]) {
                            lines.push(this.srt[i - j]);
                        }
                    }
                }

                break;
            }
        }

        return lines.length > 0 ? lines : false;
    }

    getLine(currentTime) {
        if (!this.srt && !this.fetchingSrt) {
            this.fetchingSrt = this.fetchSrt().then(() => {
                this.fetchingSrt = null;
            });
            return '';
        } else if (!this.srt) {
            return '';
        }

        for (let i = 0; i < this.srt.length; i++) {
            const line = this.srt[i];
            if (currentTime >= line.startSeconds && currentTime <= line.endSeconds) {
                return line;
            }
        }

        return false;
    }

    getLines() {
        if (!this.srt) {
            return [];
        }

        let lines = [];
        for (let i = 0; i < this.srt.length; i++) {
            lines.push(this.srt[i].text);
        }

        return lines;
    }

    getLastText() {
        return this.currentText;
    }
}

export default CreateSrtRenderer;
