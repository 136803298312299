import React, {forwardRef, useRef, useEffect, useState, useMemo} from 'react';
import {motion, useScroll, useMotionValueEvent} from 'framer-motion';

const AlwaysVisibleCanvas = forwardRef(({style, width, height, ...props}, ref) => {
    const dummyRef = useRef();
    const {scrollY} = useScroll();

    useEffect(() => {
        if (dummyRef.current) {
            dummyRef.current.width = width;
            dummyRef.current.height = height;
        }
    }, [width, height]);

    // Watch for when the scroll top reaches the top of the canvas
    // And then set the canvas via ref to position fixed  with set width and height
    // And restore it when the scroll top is below the canvas
    useMotionValueEvent(scrollY, 'change', latest => {
        const offset = 20;
        const canvas = ref.current;
        if (canvas) {
            requestAnimationFrame(() => {
                if (dummyRef.current.getBoundingClientRect().top <= offset && canvas.style.position !== 'fixed') {
                    const rect = dummyRef.current.getBoundingClientRect();

                    canvas.style.top = offset + 'px';
                    canvas.style.left = rect.left + 'px';
                    canvas.style.width = rect.width + 'px';
                    canvas.style.height = rect.height + 'px';
                    canvas.style.minWidth = '0';
                    canvas.style.minHeight = '0';
                    canvas.style.position = 'fixed';

                    // Set the canvas to be on top of everything
                    canvas.style.zIndex = 1000;
                } else if (
                    dummyRef.current.getBoundingClientRect().top > offset &&
                    canvas.style.position !== 'absolute'
                ) {
                    canvas.style.position = 'absolute';
                    canvas.style.top = 0;
                    canvas.style.left = 0;
                    canvas.style.width = 'auto';
                    canvas.style.height = 'auto';
                    canvas.style.minWidth = '100%';
                    canvas.style.minHeight = '100%';
                }
            });
        }
    });

    const canvas = useMemo(() => {
        return (
            <div
                style={{
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <img
                    ref={dummyRef}
                    key="dummy"
                    width={width}
                    height={height}
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: 'red',
                        opacity: 0,
                        zIndex: -1
                    }}
                />
                <motion.canvas
                    ref={ref}
                    key="canvas"
                    style={{
                        ...(style || {}),
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                    width={width}
                    height={height}
                    {...props}
                />
            </div>
        );
    }, [style, props]);

    return canvas;
});

export default AlwaysVisibleCanvas;
