import React, {useState, useEffect, useCallback, forwardRef, useRef} from 'react';
import {Box, Button, Flex, Text, VStack, HStack, Wrap, WrapItem} from '@chakra-ui/react';
import {FaTrash, FaCut, FaArrowLeft, FaArrowRight, FaUndo, FaRedo} from 'react-icons/fa';
import {motion} from 'framer-motion';

import {useLogger} from '../DebugLogger.js';

const WrapItemMotion = React.memo(motion(WrapItem));

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        try {
            const storedValue = localStorage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : initialValue;
        } catch (error) {
            console.error('Error reading from localStorage:', error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error writing to localStorage:', error);
        }
    }, [key, value]);

    return [value, setValue];
};

const TimingLabel = ({start, end, ...props}) => {
    return (
        <Box
            as="span"
            fontSize="xs"
            fontWeight="bold"
            color="gray.500"
            whiteSpace="nowrap"
            userSelect="none"
            mr={2}
            position="absolute"
            bottom="80%"
            opacity="var(--timing-label-opacity, 0)"
            transition="opacity 0.2s"
        >
            {parseFloat(start).toFixed(1)} - {parseFloat(end).toFixed(1)}
        </Box>
    );
};

let WordSplitter = forwardRef(({words, onChange, onClear, disableHistory}, ref) => {
    const [wordItems, setWordItems] = useState([]);
    const [realSplits, setSplits] = useLocalStorage('splits2', []);
    const [tempSplits, setTempSplits] = useState([]);
    const [historyState, setHistoryState] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [editingIndex, setEditingIndex] = useState(null);
    const handleSaveRef = React.useRef();
    const wordItemsRef = React.useRef();
    const editableRefs = useRef([]);
    const isUndoRedoActionRef = useRef(false);

    const [splits, setRealSplits] = useState(realSplits);

    useLogger(
        'WordSplitter',
        () => {
            return JSON.stringify(
                wordItems.slice(0, 5).map((item, index) => {
                    const {word, start, end} = item;
                    return {word, start, end, split: splits.includes(index)};
                }),
                null,
                2
            );
        },
        [wordItems]
    );

    useEffect(() => {
        if (words && historyState.length === 0) {
            setHistoryState([words]);
            setHistoryIndex(0);
        }
    }, [words, historyState]);

    useEffect(() => {
        if (words) {
            const lines = words.split('\n');
            let items = [];
            let newSplits = [];
            let wordIndex = 0;

            lines.forEach((line, lineIndex) => {
                const lineWords = line.split(/\s+/).filter(Boolean);
                lineWords.forEach((word, index) => {
                    const [text, start, end] = word.split('|');
                    items.push({word: text, start, end});
                    if (index === lineWords.length - 1 && lineIndex !== lines.length - 1) {
                        newSplits.push(wordIndex);
                    }
                    wordIndex++;
                });
            });

            setWordItems(items);
            setSplits(newSplits);
        }

        if (!isUndoRedoActionRef.current && !disableHistory) {
            // Add to history
            addToHistory(words);
        } else {
            setTimeout(() => {
                isUndoRedoActionRef.current = false;
            }, 10);
        }
    }, [words, disableHistory]);

    useEffect(() => {
        if (tempSplits.length > 0) {
            setRealSplits(tempSplits);
        } else {
            setRealSplits(realSplits);
        }
    }, [tempSplits, realSplits]);

    const handleWordChange = useCallback((index, newWord) => {
        setWordItems(prev => {
            const updated = [...prev];
            updated[index] = {...updated[index], word: newWord};
            return updated;
        });
    }, []);

    const handleSplit = useCallback(() => {
        if (editingIndex === null) return;

        const currentRef = editableRefs.current[editingIndex];
        const selection = window.getSelection();
        const splitIndex = selection.anchorOffset;

        setWordItems(prev => {
            const updated = [...prev];
            const currentItem = updated[editingIndex];
            const [firstPart, secondPart] = [currentItem.word.slice(0, splitIndex), currentItem.word.slice(splitIndex)];

            const totalLength = currentItem.word.length;
            const firstLength = firstPart.length;
            const secondLength = secondPart.length;

            const startTime = parseFloat(currentItem.start);
            const endTime = parseFloat(currentItem.end);
            const totalDuration = endTime - startTime;

            const firstDuration = (firstLength / totalLength) * totalDuration;
            const secondDuration = (secondLength / totalLength) * totalDuration;

            const firstEndTime = (startTime + firstDuration).toFixed(3);
            const secondStartTime = (endTime - secondDuration).toFixed(3);

            updated[editingIndex] = {
                ...currentItem,
                word: firstPart,
                end: firstEndTime
            };
            updated.splice(editingIndex + 1, 0, {
                word: secondPart,
                start: secondStartTime,
                end: currentItem.end
            });

            setSplits(prevSplits => {
                const newSplits = prevSplits.map(split => (split > editingIndex ? split + 1 : split));
                setRealSplits(newSplits);
                return newSplits;
            });

            return updated;
        });

        setTimeout(() => {
            if (currentRef) {
                currentRef.focus();
                const range = document.createRange();
                range.setStart(currentRef.firstChild, splitIndex);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }, 0);
    }, [editingIndex]);

    const handleMergePrevious = useCallback(() => {
        if (editingIndex === null || editingIndex === 0) return;

        const currentRef = editableRefs.current[editingIndex];
        const selection = window.getSelection();
        const cursorPosition = selection.anchorOffset;

        setWordItems(prev => {
            const updated = [...prev];
            const currentItem = updated[editingIndex];
            const prevItem = updated[editingIndex - 1];

            const prevLength = prevItem.word.length;

            const mergedWord = prevItem.word + currentItem.word;
            const mergedStart = prevItem.start;
            const mergedEnd = currentItem.end;

            updated[editingIndex - 1] = {
                word: mergedWord,
                start: mergedStart,
                end: mergedEnd
            };
            updated.splice(editingIndex, 1);

            setSplits(prevSplits => {
                const newSplits = prevSplits
                    .filter(split => split !== editingIndex - 1 && split !== editingIndex)
                    .map(split => (split > editingIndex ? split - 1 : split));
                setRealSplits(newSplits);
                return newSplits;
            });

            setEditingIndex(editingIndex - 1);

            setTimeout(() => {
                const newRef = editableRefs.current[editingIndex - 1];
                if (newRef) {
                    newRef.focus();
                    const range = document.createRange();
                    range.setStart(newRef.firstChild, cursorPosition + prevLength);
                    range.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }, 0);

            return updated;
        });
    }, [editingIndex]);

    const handleMergeNext = useCallback(() => {
        if (editingIndex === null || editingIndex === wordItems.length - 1) return;

        const currentRef = editableRefs.current[editingIndex];
        const selection = window.getSelection();
        const cursorPosition = selection.anchorOffset;

        setWordItems(prev => {
            const updated = [...prev];
            const currentItem = updated[editingIndex];
            const nextItem = updated[editingIndex + 1];

            const mergedWord = currentItem.word + nextItem.word;
            const mergedStart = currentItem.start;
            const mergedEnd = nextItem.end;

            updated[editingIndex] = {
                word: mergedWord,
                start: mergedStart,
                end: mergedEnd
            };
            updated.splice(editingIndex + 1, 1);

            setSplits(prevSplits =>
                prevSplits
                    .filter(split => split !== editingIndex && split !== editingIndex + 1)
                    .map(split => (split > editingIndex + 1 ? split - 1 : split))
            );

            return updated;
        });

        setTimeout(() => {
            if (currentRef) {
                currentRef.focus();
                const range = document.createRange();
                range.setStart(currentRef.firstChild, cursorPosition);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }, 0);
    }, [editingIndex, wordItems.length]);

    const handleLineSplit = useCallback(index => {
        setSplits(prevSplits => {
            const newSplits = prevSplits.includes(index)
                ? prevSplits.filter(split => split !== index)
                : [...prevSplits, index].sort((a, b) => a - b);
            return newSplits;
        });
    }, []);

    const handleUndo = useCallback(() => {
        if (historyIndex > 0) {
            isUndoRedoActionRef.current = true;
            const newIndex = historyIndex - 1;
            setHistoryIndex(newIndex);
            const previousState = historyState[newIndex];
            onChange(previousState);
        }
    }, [historyIndex, historyState, onChange]);

    const handleRedo = useCallback(() => {
        if (historyIndex < historyState.length - 1) {
            isUndoRedoActionRef.current = true;
            const newIndex = historyIndex + 1;
            setHistoryIndex(newIndex);
            const nextState = historyState[newIndex];
            onChange(nextState);
        }
    }, [historyIndex, historyState, onChange]);

    const addToHistory = useCallback(
        newState => {
            if (!isUndoRedoActionRef.current) {
                setHistoryState(prev => {
                    const newHistory = prev.slice(0, historyIndex + 1);
                    newHistory.push(newState);
                    setHistoryIndex(newHistory.length - 1);
                    return newHistory;
                });

                // Also reset index
                setHistoryIndex(historyState.length);
            }
        },
        [historyIndex]
    );

    const handleAddTempSplit = useCallback(
        index => {
            setTempSplits([...splits, index].sort((a, b) => a - b));
        },
        [splits]
    );

    const handleRemoveTempSplit = useCallback(() => {
        setTempSplits([]);
    }, []);

    const handleSave = useCallback(() => {
        let result = wordItems
            .map((item, index) => {
                const {word, start, end} = item;
                const split = splits.includes(index) ? '\n' : ' ';
                return `${word}|${start}|${end}${split}`;
            })
            .join('')
            .trim();
        onChange(result);
    }, [wordItems, splits, onChange, addToHistory]);

    const handleInput = useCallback(
        (index, event) => {
            const newWord = event.target.textContent.replace(/ /g, '');
            handleWordChange(index, newWord);
        },
        [handleWordChange]
    );

    useEffect(() => {
        handleSaveRef.current = handleSave;
    }, [handleSave]);

    useEffect(() => {
        wordItemsRef.current = {
            wordItems,
            splits: realSplits
        };
    }, [wordItems, realSplits]);

    useEffect(() => {
        let lastWordItems = wordItems;
        const saveInterval = setInterval(() => {
            const currentItemsStringed = JSON.stringify({
                wordItems: wordItemsRef.current.wordItems,
                splits: wordItemsRef.current.splits
            });
            if (lastWordItems !== currentItemsStringed) {
                handleSaveRef.current && handleSaveRef.current();
                lastWordItems = currentItemsStringed;
                console.log('Saved');
            }
        }, 800);

        return () => {
            clearInterval(saveInterval);
        };
    }, []);

    return (
        <VStack
            as={motion.div}
            ref={ref}
            spacing={4}
            align="stretch"
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            mt={4}
            maxHeight="300px"
            overflowY="auto"
        >
            <Flex justifyContent="flex-end" position="sticky" top={0} zIndex={1}>
                <Button leftIcon={<FaUndo />} onClick={handleUndo} size="sm" mr={2} isDisabled={historyIndex <= 0}>
                    Undo ({historyIndex})
                </Button>
                <Button
                    leftIcon={<FaRedo />}
                    onClick={handleRedo}
                    size="sm"
                    mr={2}
                    isDisabled={historyIndex >= historyState.length - 1}
                >
                    Redo ({historyState.length - historyIndex - 1})
                </Button>
                <Button
                    leftIcon={<FaTrash />}
                    onClick={() => {
                        setWordItems([]);
                        setSplits([]);
                        onClear();
                        addToHistory('');
                    }}
                    size="sm"
                    mr={2}
                >
                    Clear
                </Button>
            </Flex>
            <Wrap spacing={2}>
                {wordItems.map((item, index) => [
                    <WrapItemMotion
                        key={index}
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.2}}
                        zIndex={splits.includes(index) ? 1 : 0}
                    >
                        <VStack spacing={0} align="stretch">
                            <HStack
                                position="relative"
                                _hover={{
                                    '--timing-label-opacity': 1
                                }}
                            >
                                <TimingLabel start={item.start} end={item.end} />
                                <span
                                    ref={el => (editableRefs.current[index] = el)}
                                    contentEditable
                                    suppressContentEditableWarning
                                    onKeyDown={e => {
                                        if (e.key === 'Tab') {
                                            e.preventDefault();
                                            let inc = 1;

                                            if (e.shiftKey) {
                                                inc = -1;
                                            }

                                            const nextIndex = index + inc;
                                            if (nextIndex < wordItems.length && nextIndex >= 0) {
                                                editableRefs.current[nextIndex].focus();
                                                const range = document.createRange();
                                                range.setStart(
                                                    editableRefs.current[nextIndex].firstChild,
                                                    editableRefs.current[nextIndex].textContent.length
                                                );
                                                range.collapse(true);
                                                window.getSelection().removeAllRanges();
                                                window.getSelection().addRange(range);
                                            }
                                        }

                                        // 1. On pressing arrow right when at the end of the word, move to the next word index 0
                                        // 2. On pressing arrow left when at the start of the word, move to the previous word index end
                                        // 3. When pressing backspace at the start of the word, merge with previous word
                                        // 4. When pressing space inbetween start and end of the word, split the word at cursor

                                        const gotoNextWord = () => {
                                            const nextIndex = index + 1;
                                            if (nextIndex < wordItems.length) {
                                                editableRefs.current[nextIndex].focus();
                                                const range = document.createRange();
                                                range.setStart(editableRefs.current[nextIndex].firstChild, 0);
                                                range.collapse(true);
                                                window.getSelection().removeAllRanges();
                                                window.getSelection().addRange(range);
                                            }
                                        };

                                        const gotoPreviousWord = () => {
                                            const prevIndex = index - 1;
                                            if (prevIndex >= 0) {
                                                editableRefs.current[prevIndex].focus();
                                                const range = document.createRange();
                                                range.setStart(
                                                    editableRefs.current[prevIndex].firstChild,
                                                    editableRefs.current[prevIndex].textContent.length
                                                );
                                                range.collapse(true);
                                                window.getSelection().removeAllRanges();
                                                window.getSelection().addRange(range);
                                            }
                                        };

                                        // 1. On pressing arrow right when at the end of the word, move to the next word index 0
                                        if (
                                            e.key === 'ArrowRight' &&
                                            window.getSelection().anchorOffset === item.word.length
                                        ) {
                                            e.preventDefault();
                                            gotoNextWord();
                                        }

                                        // 2. On pressing arrow left when at the start of the word, move to the previous word index end
                                        if (e.key === 'ArrowLeft' && window.getSelection().anchorOffset === 0) {
                                            e.preventDefault();
                                            gotoPreviousWord();
                                        }

                                        // 3. When pressing backspace at the start of the word, merge with previous word
                                        if (e.key === 'Backspace' && window.getSelection().anchorOffset === 0) {
                                            e.preventDefault();
                                            // Focus prev word and set cursor to end
                                            handleMergePrevious();
                                        }

                                        // 4. When pressing space inbetween start and end of the word, split the word at cursor
                                        if (e.key === ' ' && window.getSelection().anchorOffset > 0) {
                                            e.preventDefault();

                                            handleSplit();

                                            setTimeout(() => {
                                                gotoNextWord();
                                            }, 0);
                                        }
                                    }}
                                    /*onInput={e => {
                                        handleInput(index, e);
                                    }}*/
                                    onBlur={e => {
                                        handleInput(index, e);
                                        if (window.blurringEditingIndex) {
                                            clearTimeout(window.blurringEditingIndex);
                                        }
                                        window.blurringEditingIndex = setTimeout(() => {
                                            setEditingIndex(null);
                                        }, 100);
                                    }}
                                    onFocus={() => {
                                        if (window.blurringEditingIndex) {
                                            clearTimeout(window.blurringEditingIndex);
                                            window.blurringEditingIndex = null;
                                        }
                                        setEditingIndex(index);
                                    }}
                                    style={{
                                        display: 'inline-block',
                                        minWidth: '1em',
                                        padding: '2px 4px',
                                        border: '1px solid transparent',
                                        borderRadius: '2px',
                                        outline: 'none'
                                    }}
                                >
                                    {item.word}
                                </span>
                                <Button
                                    onClick={() => {
                                        handleLineSplit(index);
                                    }}
                                    transition="background-color 0.2s"
                                    size="sm"
                                    variant={splits.includes(index) ? 'solid' : 'ghost'}
                                    colorScheme={splits.includes(index) ? 'green' : 'gray'}
                                    h="24px"
                                    p={0}
                                    m={0}
                                    mx="0px"
                                    minW="24px"
                                    _hover={{
                                        bg: (() => {
                                            let color = 'gray';
                                            if (realSplits.includes(index)) {
                                                color = 'red';
                                            } else if (tempSplits.includes(index)) {
                                                color = 'purple';
                                            }
                                            return color;
                                        })(),
                                        color: 'white !important'
                                    }}
                                >
                                    ↵
                                </Button>
                            </HStack>
                            {0 && editingIndex === index && (
                                <HStack key="edit-actions">
                                    <Button size="xs" onClick={handleMergePrevious} isDisabled={index === 0}>
                                        <FaArrowLeft />
                                    </Button>
                                    <Button size="xs" onClick={handleSplit}>
                                        <FaCut />
                                    </Button>
                                    <Button
                                        size="xs"
                                        onClick={handleMergeNext}
                                        isDisabled={index === wordItems.length - 1}
                                    >
                                        <FaArrowRight />
                                    </Button>
                                </HStack>
                            )}
                        </VStack>
                    </WrapItemMotion>,
                    splits.includes(index) && <WrapItem key={`split-${index}`} flexBasis="100%" height={0} />
                ])}
            </Wrap>
        </VStack>
    );
});

WordSplitter.displayName = 'WordSplitter';

WordSplitter = React.memo(WordSplitter);

export default WordSplitter;
