import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';

/*
    Not shown until the user drags a file over the drop area, then its opacity is set to 1.
    Its pointer-events property is set to none so that the user can still interact with the items behind it.
    Its position is set to absolute so that it doesn't affect the layout of the parent container.

    It adds a global event and is shown when a file is dragged anywhere on the page.
    When the file is dragged over the drop area, the event is triggered and the opacity of the drop area is set to 1.
*/

const DropArea = styled(motion.div)`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // Green
    background-color: rgba(0, 255, 0, 0.1);
    border: 2px dashed rgba(0, 255, 0, 0.5);
    border-radius: 10px;
    position: absolute;
    opacity: 0;
    z-index: 1;
`;

const FileInputDragNDrop = ({children, onDrop, ...props}) => {
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        const handleDragEnter = e => {
            e.preventDefault();
            setDragging(true);
        };

        const handleDragLeave = e => {
            e.preventDefault();
            setDragging(false);
        };

        document.querySelector('html').addEventListener('dragover', handleDragEnter);
        document.querySelector('html').addEventListener('dragleave', handleDragLeave);

        return () => {
            document.querySelector('html').removeEventListener('dragover', handleDragEnter);
            document.querySelector('html').removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    return (
        <DropArea
            animate={{opacity: dragging ? 1 : 0}}
            transition={{duration: 0.2}}
            style={{pointerEvents: dragging ? 'all' : 'none'}}
            onDrop={e => {
                console.log('onDrop', e);
                e.preventDefault();
                setDragging(false);
                onDrop({
                    target: {
                        files: e.dataTransfer.files
                    }
                });
            }}
            {...props}
        />
    );
};

export default FileInputDragNDrop;
