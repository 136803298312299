import React from 'react';

// HighlightedLog component
// Dim labels (text before ":") and highlight the rest, special  colors for numbers, including decimals
const HighlightedLog = ({log, ...props}) => {
    let [label, ...parts] = log ? `${log}`.split(':') : [];

    if (parts.length === 0) {
        parts = [label];
        label = '';
    }

    return (
        <div {...props}>
            <span style={{color: 'dimgray'}}>{label}</span>
            {parts.map((part, i) => {
                // Figure out and colorize numbers
                const words = part.split(/(\d+(?:\.\d+)?)/g);
                return (
                    <span key={i}>
                        {words.map((word, j) => {
                            const num = parseFloat(word);
                            return isNaN(num) ? (
                                <span key={j}>{word}</span>
                            ) : (
                                <span key={j} style={{color: 'darkorange'}}>
                                    {num}
                                </span>
                            );
                        })}
                    </span>
                );
            })}
        </div>
    );
};

export default HighlightedLog;
